import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Layout from '../components/layout/layout.vue';
import LayoutProgrammation from '../components/layout/layout-programmation.vue';
import LayoutAppointmentGeneration from '../components/layout/layout-appointment-generation.vue'
import PatternList from '../pages/pattern-list/pattern-list.vue';
import PatternManage from '../pages/pattern-manage/pattern-manage.vue';
import ThemeCategorieRdv from '../pages/theme-category-rdv/theme-category-rdv.vue';
import PatternCreate from '../pages/pattern-create/pattern-create.vue';
import AppointmentGeneration from '../pages/appointment-generation/appointment-generation.vue';
import Programmation from '../pages/programming/programmation.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {name:'sandbox',path: '/', redirect: 'gestion-rendez-vous/gestion-motifs'},
      {
        path: '/layout',
        name: 'Layout',
        component: Layout,
        children: [
          {
            path: '/gestion-rendez-vous/gestion-motifs',
            name: 'PatternList',
            component: PatternList
          },
          {
            path: '/gestion-rendez-vous/themes-categories-lieux',
            name: 'ThemeCategorieRdv',
            component: ThemeCategorieRdv
          },
          {
            path: '/edition-rendez-vous/edition-motifs-de-rendez-vous',
            name: 'PatternCreate',
            component: PatternCreate
          },
          {
            path: '/gestion-rendez-vous/gestion-motifs/motif',
            name: 'PatternManage',
            component: PatternManage
          },
        ]
  },
  {
    path: '/layoutProgrammation',
    name: 'LayoutProgrammation',
    component: LayoutProgrammation,
    children: [
      {
        path: '/program_cal',
        name: 'Programmation',
        component: Programmation
      },
    ]
  },
  {
    path: '/LayoutAppointmentGeneration',
    name: 'LayoutAppointmentGeneration',
    component: LayoutAppointmentGeneration,
    children: [
      {
        path: '/edition-rendez-vous/generation-des-rendez-vous',
        name: 'AppointmentGeneration',
        component: AppointmentGeneration
      },
    ]
  },
      
];

const router = new VueRouter({
  mode: 'history',
  base: 'schedule-app/',
  routes,
});

export default router;