





































































import { Vue } from "vue-property-decorator";
import Recurrence from "./recurrence/recurrence.vue"
import { appMixin } from '@/mixins/app.mixin';
import { AppointmentScheduleTemplate } from "@/models/appointment/appointmentModel";
import { BusinessCategoryModel, BusinessPurpose } from "@/models/metadata/businessMetadata";
import vSelect from "vue-select";
import { mapGetters, mapActions} from 'vuex'
import moment from 'moment/moment';
import { RessourceStateEnum } from "@/common/api.enums";
import { ScheduleTemplateCreateDto } from '@/dto/schedule/scheduleDto';
export default Vue.extend({
    name: 'CalendarHeader',
    components: {
        Recurrence,
        vSelect
    },
    mixins: [appMixin],
    props:{
        /**
         * Show SideBar
         */
        showSide:{ type: Boolean, default: true,},
    },
    data(){
        return {
            service: '',
            serviceName: '',
            purpose: '',
            category: '',
            template: '',
            showside: this.showSide,
            activatePubishBtn: false,
            activateGenerateBtn: true,
            form: {
                dateStart: '',
                dateEnd: '',
            },
            purposeOptions: new Array<{value: string, text: string}>(),
            categoryOptions: new Array<{value: string, text: string}>(),
            templateOptions: new Array<{value: string, text: string}>(),
            businessPurposeProjection: new Array<BusinessPurpose>(),
            scheduleTemplateOfCategory: new Array<AppointmentScheduleTemplate>()
        }
    },
    computed: {
        ...mapGetters("scheduleTemplate", ['getScheduleTemplate', 'getScheduleTemplates', 'getAppointments_']),
        ...mapGetters("businessPurpose", ['getBusinessPurposeSelectBox', 'getBusinessPurpose', 'getBusinessPurposes']),
        ...mapGetters("businessCategory", ['getBusinessCategory']),
        ...mapGetters('layout', ['getSidebarActiveElement', 'getActiveSidebarAppointementGeneration']),
        ...mapGetters("organization", ["getOrganizations"]),
        activeSidebar(): boolean{
            return this.getActiveSidebarAppointementGeneration;
        },
        _activateGenerateBtn(): boolean{
            if(
                (this.form.dateStart !== '') && 
                (this.form.dateEnd !== '') && 
                (this.template !== '') && 
                (this.activateGenerateBtn !== false)
            ){
                return false
            }else{
                return true
            }
        },
    },
    methods: {
        ...mapActions("businessPurpose", ['retrieveBusinessPurpose', 'retrieveBusinessPurposes']),
        ...mapActions("businessCategory", ['retriveBusinessCategory']),
        ...mapActions("scheduleTemplate", ['retrieveScheduleTemplate', 'setScheduleAppointmentOverride',
            'clearScheduleAppointments', 'publishScheduleAppointment', 'createAppointmentScheduleTemplate'
        ]),
        ...mapActions("layout", ['setActiveSidebarAppointementGeneration']),
        ...mapActions("admin", ['setAppointmentGenerationSpinner']),
        toggleSidebar(): any {
            this.showside = !this.showside;
        },
        onSubmit() {
            let scheduleTemplate: ScheduleTemplateCreateDto = this.getScheduleTemplate
            scheduleTemplate.schedule_start_time = moment(this.form.dateStart).add(1, 'day').format('YYYY-MM-DDTHH:mm:ss')
            console.log('start: ', scheduleTemplate.schedule_start_time)
            scheduleTemplate.schedule_end_time = this.form.dateEnd + 'T23:59:59.0'
            this.setAppointmentGenerationSpinner(true);
            this.btnDisable=true
            this.createAppointmentScheduleTemplate({
                metadata: scheduleTemplate.metadata,
                schedule_start_time: scheduleTemplate.schedule_start_time,
                schedule_end_time: scheduleTemplate.schedule_end_time,
                schedule_days: scheduleTemplate.schedule_days,
                access_control: scheduleTemplate.access_control
            }).then((value: boolean) => {
                if(value === true){
                    this.activatePubishBtn=true
                    this.activateGenerateBtn=false 
                }
                this.setAppointmentGenerationSpinner(false);
            })
            this.$root.$emit('bv::hide::modal', 'recurrenceModal', '#btnSubmit')
        },
        onDateStartSet(value: string){
            this.form.dateStart = value
        },
        onDateEndSet(value: string){
            this.form.dateEnd = value
        },
        async filterPurpose(value: string): Promise<BusinessPurpose[]> {
            return this.getBusinessPurposes.filter((purpose: BusinessPurpose) => purpose.organization_unit == value)
        },
        async onServiceSet(value: string){
            this.filterPurpose(value).then(val => {
                this.service = value
                this.businessPurposeProjection = val
                this.purposeOptions = this.businessSelectBox(this.businessPurposeProjection.filter((businessPurpose) => businessPurpose.state === RessourceStateEnum.Published))
            })
        },
        async onPurposeSet(value: string) {
            this.purpose = value
            await this.retrieveBusinessPurpose(value).then( () => { 
                this.categoryOptions = this.categorySelectBox(this.getBusinessPurpose.categories.filter((businessCategory: BusinessCategoryModel) => businessCategory.state === RessourceStateEnum.Published))
            })
        },
        async onCategorySet(value: string){
            this.category = value
            this.scheduleTemplateOfCategory = []
            this.getScheduleTemplates.forEach((template: AppointmentScheduleTemplate) => {
                if(template.metadata.business_reference.category_id === value) {
                    this.scheduleTemplateOfCategory.push(template)
                }
            });
            this.templateOptions = this.scheduleTemplateSelectBox(this.scheduleTemplateOfCategory.filter((template) => template.state === RessourceStateEnum.Published))
        },
        async onTemplateSet(value: string) {
            this.template = value
            this.retrieveScheduleTemplate(value)
        },
        purposeSet(value: string){
            if (value){
                this.purpose = value
            }
        },
        categorySet(value: string){
            if (value){
                this.category = value
            }
        },
        templateSet(value: string){
            if (value){
                this.template = value
            }
        },
        dateInitialization() {
            const now = new Date()
            const afterOneMonth = new Date(now)
            afterOneMonth.setMonth(now.getMonth() + 1)
            this.form.dateStart = this.formattingDate(now)
            this.form.dateEnd = this.formattingDate(afterOneMonth)
        },
        formattingDate(value: Date): string{
            return moment(value).format('YYYY-MM-DD');
        },
        onPublished(event: Event) {
            event.preventDefault();
            if (this.getAppointments_) {
                this.setAppointmentGenerationSpinner(true)
                this.btnDisable=true
                this.publishScheduleAppointment(this.getAppointments_).then((value: boolean) => {
                    if(value === true){
                        this.activatePubishBtn=false
                        this.activateGenerateBtn=true 
                        this.template = ''
                    }
                    this.setAppointmentGenerationSpinner(false)
                })
            }
        },
        onClear(){
            this.initial()
            console.log('clear')
            this.clearScheduleAppointments([])
        },
        async initial() {
            this.purpose = '',
            this.category = '',
            this.template = '',
            this.activatePubishBtn = false,
            this.activateGenerateBtn = true,
            this.dateInitialization()
            await this.onServiceSet(this.getOrganizations[1].value)
            await this.onPurposeSet(this.purposeOptions[1].value)
            await this.onCategorySet(this.categoryOptions[1].value).then(() => {
                if(this.templateOptions.length > 1){
                    this.templateSet(this.templateOptions[1].value)
                }        
                if(this.template !== '') {
                    this.retrieveScheduleTemplate(this.template)
                }
            })  
        },
    },
    created() {
        this.dateInitialization()
        this.retrieveBusinessPurposes().then(
            async () => { 
                await this.initial()
            }
        )
        
    },
})   
