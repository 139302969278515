
















































import Vue from 'vue'
import ScheduleTableHeaderModified from './schedule-table-header/schedule-table-header-modified.vue';
import ScheduleTableInnerModified from './schedule-table-inner/schedule-table-inner-modified.vue';
import { ScheduleSpan } from '@/models/schedule/scheduleModel';
import { ScheduleStub, TimeUnit } from '@/common/api.enums';
import { Duration } from '@/models/schedule/duration';
import { mapActions, mapGetters } from 'vuex'
export default Vue.extend({
    name: 'ScheduleTemplateSetterModified',
    components: {
        ScheduleTableHeaderModified,
        ScheduleTableInnerModified
    },
    data(){
        return {
            daysSelected: new Array<string>(),
            days: [
                {id: '1', value: 'monday', name:'Lundi', status: true},
                {id: '2', value: 'tuesday', name:'Mardi', status: true},
                {id: '3', value: 'wednesday', name:'Mercredi', status: true},
                {id: '4', value: 'thursday', name:'Jeudi', status: true},
                {id: '5', value: 'friday', name:'Vendredi', status: true},
                {id: '6', value: 'saturday', name:'Samedi', status: true},
                {id: '7', value: 'sunday', name:'Dimanche', status: true},
            ],
            days_: [],
            selectedDay: new Map<string, ScheduleSpan[]>(),
            exempleMap: new Map<string, string>()
        }
    },
    computed: {
        ...mapGetters("scheduleTemplate",['getScheduleDays', 'getScheduleDuration', 'getScheduleGraceTime', 'getScheduleTemplate']),
    },
    methods: {
        ...mapActions("scheduleTemplate",['setScheduleDays']),
        initialShow(status: boolean): string {
            return String(status)
        },
        daySelectedOrNot(...value: any) {
            const [day, status] = value
            let duration_: Duration = { length: 10, time_unit: TimeUnit.Minutes }
            let graceTime: Duration = { length: 0, time_unit: TimeUnit.Minutes }
            
            if(this.getScheduleDuration !== undefined) { duration_ = this.getScheduleDuration }
            if(this.getScheduleGraceTime !== undefined) { graceTime = this.getScheduleGraceTime }
            let tempMorning: ScheduleSpan = { start_time: '', end_time: '', duration: duration_, grace_time: graceTime,
                                              schedule_stub: ScheduleStub.Start, occurrence: 0, exclude_weekend: false, exclude_holidays: true}
            let tempAfternoon: ScheduleSpan = { start_time: '', end_time: '', duration: duration_, grace_time: graceTime,
                                               schedule_stub: ScheduleStub.Start, occurrence: 0, exclude_weekend: false, exclude_holidays: true}
            let scheduleSpans = new Array<ScheduleSpan>()
            scheduleSpans.push(tempMorning, tempAfternoon)
            if (!(status == "true")) {
                if(!(this.selectedDay.has(day)))
                    this.selectedDay.set(day, scheduleSpans)
            }else {
                this.selectedDay.delete(day)
            }
            this.setScheduleDays(this.selectedDay)
        },
        initialization() {
            this.selectedDay = this.getScheduleDays
            this.days.forEach((day) => {
                if (this.getScheduleDays.has(day.value)){
                    day.status = !day.status
                }
            })
        }
    },
    beforeMount() {
        this.initialization()
    }
})
