

















import { Vue } from "vue-property-decorator";
import SidebarApp from '@/components/layout/sidebar-app/sidebar-app.vue';
import RightSidebar from '@/components/layout/sidebar-app/right-sidebar-generation/right-sidebar-generation.vue';
import { layoutMixin } from '@/mixins/layout.mixin';
import { mapGetters, mapActions } from "vuex";
export default Vue.extend({
  name: "LayoutAppointmentGeneration",
  components: {
    SidebarApp,
    RightSidebar
  },
  mixins: [layoutMixin],
  data(){
    return {
      showmenu: false,
      isOpen: false,
      showSide: true
    }
  },
  computed: {
    ...mapGetters("layout",['getActiveSidebar', 'getActiveSidebarAppointementGeneration']),
  },
  methods: {
    ...mapActions("layout", ['setActiveSidebar', 'setActiveSidebarAppointementGeneration']),
    ...mapActions("businessPurpose", ["retrieveBusinessPurposes"]),
    ...mapActions("appointmentLocation", ['retrieveAppointmentLocationForUser']),
    ...mapActions("scheduleTemplate", ['retrieveScheduleTemplates']),
    toggleSidebar(activeSidebar: boolean) {
      if(activeSidebar !== true ){
        this.setActiveSidebar(true)
      }else{
        this.setActiveSidebar(false)
      }
    },
  },
});
