import {Module} from "vuex";
import {RootState} from "@/store/types";
import {getters} from "@/store/modules/businessCategory/businessCategory-getters";
import {actions} from "@/store/modules/businessCategory/businessCategory-actions";
import {mutations} from "@/store/modules/businessCategory/businessCategory-mutations";
import { businessCategoryState } from "./types";
export const state: businessCategoryState = {
    businessCategory : undefined,
    businessCategories: [],
    businessCategoryTable: [],
    categoryPurposes: [],
    businessCategoriesPublished: [],
    filterStatut: null,
    filterSearchText: null,
    filterPurposeSearch: null
};

export const businessCategory: Module<businessCategoryState, RootState> = {
    namespaced : true,
    state,
    getters,
    actions,
    mutations,
};
