import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { AuthState } from './types';
import { User } from "@/plugins/shield/models/user";

export const getters: GetterTree<AuthState, RootState> = {
  getUser(state): User | null {
    return state.user;
  },
};
