import { render, staticRenderFns } from "./pattern-list.vue?vue&type=template&id=255d6118&scoped=true&"
import script from "./pattern-list.vue?vue&type=script&lang=ts&"
export * from "./pattern-list.vue?vue&type=script&lang=ts&"
import style0 from "./pattern-list.scss?vue&type=style&index=0&id=255d6118&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "255d6118",
  null
  
)

export default component.exports