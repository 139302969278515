
































































































import Vue from 'vue'
import FormInput from '@/components/form-element/form-input/form-input.vue';
import FormSelect from '@/components/form-element/form-select/form-select.vue';
import { mapActions, mapGetters } from 'vuex'
import { LocationTypeEnum, RessourceStateEnum } from '@/common/api.enums';
import { Utils } from '@/common/utils';
export default Vue.extend({
    name: 'LocationManage',
    components:{
        FormInput,
        FormSelect
    },
    props: [],
    data() {
        const Type_Location = [{value: LocationTypeEnum.Virtual, text: 'Virtuelle'},
                               {value: LocationTypeEnum.Physical, text: 'Physique' }]      
        return {
            form: {
                location: '',
                display_name: '',
                location_type: '',
                street_num: '',
                street: '',
                street2: '',
                building: '',
                office: '',
                city: '',
                state: '',
                country: ''
            },
            validate: {
                location: '',
                street: '',
                building: '',
                office: '',
                city: '',
                country: ''
            },
            disabled: true,
            deleteBtn: true,
            location_types: Type_Location,
            pageTitle: 'Nouveau motif de rendez'
        }
    },
    computed: {
        ...mapGetters('appointmentLocation', ['getAppointmentLocation', 'getAppointmentLocations']),  
        ...mapGetters("admin", ["getTableRow"]),      
    },
    methods: {
        ...mapActions("appointmentLocation", ['updateAppointmentLocation', 'deleteAppointmentLocation']),
        ...mapActions("admin", ["setTableRow"]),
        onLocationNameSet(value: string){
            this.form.location = value
        },
        onLocationDisplayNameSet(value: string){
            this.form.display_name = value
        },
        onLocationTypeSet(value: string){
            this.form.location_type = value
        },
        onLocationStreetNumSet(value: string){
            this.form.street_num = value
        },
        onLocationStreetSet(value: string){
            this.form.street = value
        },
        onLocationStreet2Set(value: string){
            this.form.street2 = value
        },
        onLocationBuildingSet(value: string){
            this.form.building = value
        },
        onLocationOfficeSet(value: string){
            this.form.office = value
        },
        onLocationCitySet(value: string){
            this.form.city = value
        },
        onLocationCountrySet(value: string){
            this.form.country = value
        },
        validation(): boolean{
            let validationState = true
            if(this.form.location_type === LocationTypeEnum.Virtual){
                if(this.form.location === '') {
                    this.validate.location = 'false'
                    validationState = false
                }else{
                    this.validate.location = ''
                }
            }else{
                if(this.form.location === ''){
                    validationState = false
                    this.validate.location = 'false'
                }else{
                    this.validate.location = ''
                }
                if(this.form.street === ''){
                    validationState = false
                    this.validate.street = 'false'
                }else{
                    this.validate.street = ''
                }
                if(this.form.office === ''){
                    validationState = false
                    this.validate.office = 'false'
                }else{
                    this.validate.office = ''
                }
                if(this.form.city === ''){
                    validationState = false
                    this.validate.city = 'false'
                }else{
                    this.validate.city = ''
                }
                if(this.form.building === ''){
                    validationState = false
                    this.validate.building = 'false'
                }else{
                    this.validate.building = ''
                }
            }
            return validationState
        },
        onRegister(event: Event) {
            event.preventDefault()
            if(this.form.location.trim() !== '') {
                this.updateAppointmentLocation({
                    name: this.form.location,
                    display_name: this.form.display_name,
                    location_type: this.form.location_type,
                    address: {
                        street_num: this.form.street_num,
                        street: this.form.street,
                        street2: this.form.street2,
                        building: this.form.building,
                        office: this.form.office,
                        city: this.form.city,
                        state: this.form.state,
                        country: this.form.country
                    },
                    business_units: {
                        ou: "ETS",
                        name: "Etat Civile",
                        description: "Service d etat civile"
                    },
                    referral_owners: null,
                    access_control: {
                        write: {
                            groups: ["TEST"]
                        }
                    },
                    state: RessourceStateEnum.Draft,
                    location_id: this.getAppointmentLocation.location_id
                }).then(() => {
                    this.$root.$emit('bv::hide::modal', 'locationManageModal', '#btnSubmit')
                })
                
            }
        },
        onPublished(event: Event) {
            event.preventDefault()
            if(this.validation() === true) {
                this.updateAppointmentLocation({
                    name: this.form.location,
                    display_name: this.form.display_name,
                    location_type: this.form.location_type,
                    address: {
                        street_num: this.form.street_num,
                        street: this.form.street,
                        street2: this.form.street2,
                        building: this.form.building,
                        office: this.form.office,
                        city: this.form.city,
                        state: this.form.state,
                        country: this.form.country
                    },
                    business_units: {
                        ou: "ETS",
                        name: "Etat Civile",
                        description: "Service d etat civile"
                    },
                    referral_owners: null,
                    access_control: {
                        write: {
                            groups: ["TEST"]
                        }
                    },
                    state: RessourceStateEnum.Published,
                    location_id: this.getAppointmentLocation.location_id
                }).then(() => {
                    this.$root.$emit('bv::hide::modal', 'locationManageModal', '#btnSubmit')
                })
            }
        },
        showModal() {
            this.form.location = this.getAppointmentLocation.name
            this.form.display_name = this.getAppointmentLocation.display_name
            this.form.location_type = this.getAppointmentLocation.location_type
            if(this.form.location_type === LocationTypeEnum.Physical){
                this.form.street_num = this.getAppointmentLocation.address.street_num
                this.form.street = this.getAppointmentLocation.address.street
                this.form.street2 = this.getAppointmentLocation.address.street2
                this.form.building = this.getAppointmentLocation.address.building
                this.form.office = this.getAppointmentLocation.address.office
                this.form.city = this.getAppointmentLocation.address.city
                this.form.country = this.getAppointmentLocation.address.country
            }
            if(this.getAppointmentLocation.state === RessourceStateEnum.Draft){
                this.disabled = false
                this.deleteBtn = false
            }else{
                this.disabled = true
                this.deleteBtn = true
            }
        },
        editable(value: boolean): boolean{
            this.disabled = !value;
            return !value
        },
        hideModal(){
            Utils.clearForm(this.validate)
            Utils.clearForm(this.form)
        },
        deleteLocation(event: Event) {
            event.preventDefault();
            this.deleteAppointmentLocation(this.getAppointmentLocation.location_id)
                .then(() => {
                    this.setTableRow({...this.getTableRow, location: this.getAppointmentLocations.length})
                    this.$root.$emit('bv::hide::modal', 'locationManageModal', '#btnDelete')
                })
        },
    },
})
