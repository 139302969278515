

















































import Vue from 'vue'
import ScheduleTableHeader from '@/components/schedule-template/schedule-template-setter/schedule-table-header/schedule-table-header.vue';
import ScheduleTableInner from '@/components/schedule-template/schedule-template-setter/schedule-table-inner/schedule-table-inner.vue';
import { ScheduleSpan } from '@/models/schedule/scheduleModel';
import { ScheduleStub, TimeUnit, TemplateTime } from '@/common/api.enums';
import { Duration } from '@/models/schedule/duration';
import { mapActions, mapGetters } from 'vuex'
export default Vue.extend({
    name: 'ScheduleTemplateSetter',
    components: {
        ScheduleTableHeader,
        ScheduleTableInner
    },
    data(){
        return {
            daysSelected: new Array<string>(),
            days: [
                {id: '1', value: 'monday', name:'Lundi', status: true},
                {id: '2', value: 'tuesday', name:'Mardi', status: true},
                {id: '3', value: 'wednesday', name:'Mercredi', status: true},
                {id: '4', value: 'thursday', name:'Jeudi', status: true},
                {id: '5', value: 'friday', name:'Vendredi', status: true},
                {id: '6', value: 'saturday', name:'Samedi', status: true},
                {id: '7', value: 'sunday', name:'Dimanche', status: true},
            ],
            selectedDay: new Map<string, ScheduleSpan[]>(),
            exempleMap: new Map<string, string>(),
            morning: {
                start: TemplateTime.Morning_start,
                end: TemplateTime.Morning_end
            },
            afternoon: {
                start: TemplateTime.Afternoon_start,
                end: TemplateTime.Afternoon_end
            }
        }
    },
    computed: {
        ...mapGetters("scheduleTemplate",['getScheduleDays', 'getScheduleDuration', 'getScheduleGraceTime', 'getScheduleExcludeFreeday']),
    },
    methods: {
        ...mapActions("scheduleTemplate",['setScheduleDays']),
        daySelectedOrNot_(...value: any) {
            const [day, status] = value
            if (!(status == "true")) {
                this.daysSelected.push(day)
            }else{
                const index = this.daysSelected.indexOf(day, 0);
                if (index > -1) {
                    this.daysSelected.splice(index, 1);
                }
            }
        },
        daySelectedOrNot(...value: any) {
            const [day, status] = value
            let duration_: Duration = { length: 10, time_unit: TimeUnit.Minutes }
            let graceTime: Duration = { length: 0, time_unit: TimeUnit.Minutes }
        
            if(this.getScheduleDuration) { duration_ = this.getScheduleDuration }
            if(this.getScheduleGraceTime) { graceTime = this.getScheduleGraceTime }
            let tempMorning: ScheduleSpan = { start_time: '', end_time: '', duration: duration_, grace_time: graceTime,
                                              schedule_stub: ScheduleStub.Start, occurrence: 0, exclude_weekend: this.getScheduleExcludeFreeday, exclude_holidays: true}
            let tempAfternoon: ScheduleSpan = { start_time: '', end_time: '', duration: duration_, grace_time: graceTime,
                                               schedule_stub: ScheduleStub.Start, occurrence: 0, exclude_weekend: this.getScheduleExcludeFreeday, exclude_holidays: true}
            let scheduleSpans = new Array<ScheduleSpan>()
            scheduleSpans.push(tempMorning, tempAfternoon)
            if (!(status == "true")) {
                this.selectedDay.set(day, scheduleSpans)
            }else {
                this.selectedDay.delete(day)
            }
            this.setScheduleDays(this.selectedDay)
        },
    }
})
