



























































import { Vue } from "vue-property-decorator";
import { mapGetters, mapActions } from 'vuex';
import moment from "moment/moment";  
import { ParticipantModel } from "@/models/participant/participantModel";
import { appMixin } from '@/mixins/app.mixin';
export default Vue.extend({
    name: 'ProgrammingDetail',
    components:{
    },
    mixins: [appMixin],
    props: [],
    data() {
        const now = new Date()
        const afterSevenDays = new Date(now)
        afterSevenDays.setDate(now.getDate() + 7)
        return {
            form: {
                participant: {}
            },
            participantsSelected: [{}],
            clientParticipant: 'SEDZRO Yao Derick',
            participants: [
                {
                    user_name: "Derick",
                    ou: undefined,
                    full_name: "SEDZRO Yao Derick",
                    own_ship: false,
                    is_business_unit: undefined,
                    meta_data: undefined,
                },
                {
                    user_name: "Nuno",
                    ou: undefined,
                    full_name: "Nuno Mendes",
                    own_ship: true,
                    is_business_unit: undefined,
                    meta_data: undefined,
                },
                {
                    user_name: "Justin",
                    ou: undefined,
                    full_name: "Justin Adjassem",
                    own_ship: false,
                    is_business_unit: undefined,
                    meta_data: undefined,
                },
                {
                    user_name: "Lucas",
                    ou: undefined,
                    full_name: "Kouami Lucas",
                    own_ship: false,
                    is_business_unit: undefined,
                    meta_data: undefined,
                },
                {
                    user_name: "Erick",
                    ou: undefined,
                    full_name: "Drumon Erick",
                    own_ship: false,
                    is_business_unit: undefined,
                    meta_data: undefined,
                },
                {
                    user_name: "Sergios",
                    ou: undefined,
                    full_name: "Sergio Dos Ramos",
                    own_ship: false,
                    is_business_unit: undefined,
                    meta_data: undefined,
                },
            ],
        }
    },
    computed: {
        ...mapGetters("scheduleTemplate", ["getProgrammingAppointment"]),
        clientParticipantInfo(): Record<string, unknown> {

            if (this.getProgrammingAppointment.extendedProps.participants[1]) {
                return {
                    full_name: this.getProgrammingAppointment.extendedProps.participants[1].full_name,
                    phone: this.getProgrammingAppointment.extendedProps.participants[1].metadata.phone,
                    email: this.getProgrammingAppointment.extendedProps.participants[1].metadata.email
                }
            }
            return {
                full_name: "",
                phone: "",
                email: ""
            }
        },
        appointmentDate(): string {
            return moment(this.getProgrammingAppointment.start).locale('fr').format("DD MMMM YYYY")
        },
        appointmentStart(): string {
            return moment(this.getProgrammingAppointment.start).format("hh:mm")
        },
        appointmentEnd(): string {
            return moment(this.getProgrammingAppointment.end).format("hh:mm")
        },
        participantSelectBox(): Array<{
            value: ParticipantModel | string;
            text: string | undefined;
        }> {
            return this.participantSelectBox(this.participants);
        },
    },
    methods: {
        ...mapActions("scheduleTemplate", ['updateProgrammingAppointement']),
        makeToast(variant: string, message: string, title: string) {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant,
                solid: true,
                toaster: 'b-toaster-bottom-right',
                autoHideDelay: 5000
            })
        }, 
        onParticipantSet(value: ParticipantModel) {
            this.form.participant = {
                ...value,
                own_ship: true,
            };
            this.participantsSelected[0] = this.form.participant 
        },
        _onSubmit(){
            this.$root.$emit("bv::hide::modal", "programmingDetail", "#rowShow")
        },
        onSubmit(){
            this.updateProgrammingAppointement({
                id: this.getProgrammingAppointment.id,
                title: this.getProgrammingAppointment.title,
                start: moment(this.getProgrammingAppointment.start).format("YYYY-MM-DD[T]HH:mm:ss") + ".0",
                end: moment(this.getProgrammingAppointment.end).format("YYYY-MM-DD[T]HH:mm:ss") + ".0",
                extendedProps: {
                    ...this.getProgrammingAppointment.extendedProps,
                    participants: this.participantsSelected
                },
            }).then(() => {
                this.$root.$emit("bv::hide::modal", "programmingDetail", "#rowShow")
            });
        },
        onParticipantSelected(value: ParticipantModel) {
            this.form.participant = {
                ...value,
                own_ship: true,
            };
            this.participantsSelected[0] = this.form.participant 
        },
        showModal() {
            this.participantsSelected = this.getProgrammingAppointment.extendedProps.participants
        }
    },
})
