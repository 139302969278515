











import Vue from 'vue'
import ScheduleTimer from '@/components/schedule-timer/schedule-timer.vue'
import { mapActions, mapGetters } from 'vuex';
import { ScheduleSpan } from '@/models/schedule/scheduleModel';
export default Vue.extend({
    name: 'ScheduleTableInner',
    props: [
        'journey',
        'disabled',
        'name',
        'startOrEnd',
        'min',
        'max'
    ],
    components:{
        ScheduleTimer
    },
    data(){
        return{
            showButton: true,
            scheduleSpans: new Array<ScheduleSpan>()
        }
    },
    computed: {
        ...mapGetters("scheduleTemplate",['getScheduleDays']),
    },
    methods: {
        ...mapActions("scheduleTemplate",['setScheduleDay']),
        hideButton(){
            this.showButton = false
        },
        buttonShow(){
            this.showButton = true
            this.setScheduleDay(this.scheduleDayUpdate())
        },
        scheduleDayUpdate(): Map<string, ScheduleSpan[]>{
            this.scheduleSpans = this.getScheduleDays.get(this.name)
            if(this.journey == 'morning') {
                if(this.startOrEnd == 'start') {
                    this.scheduleSpans[0].start_time = ''
                }else{
                    this.scheduleSpans[0].end_time = ''
                }
            }else{
                if(this.startOrEnd == 'start') {
                    this.scheduleSpans[1].start_time = ''
                }else{
                    this.scheduleSpans[1].end_time = ''
                }
            }
            let mapScheduleSpan = new Map<string, ScheduleSpan[]>()
            mapScheduleSpan.set(this.name, this.scheduleSpans)
            return mapScheduleSpan
        },
    }
})
