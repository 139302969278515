































import { Vue } from "vue-property-decorator";
import TableFilter from "@/components/table-filter/table-filter.vue";
import TableView from '@/components/table-view/table-view.vue';
import FilterPrime from "@/components/table-filter/filter.vue";
import { mapActions, mapGetters } from "vuex";
export default Vue.extend({
    name: "LocationsTab",
    components:{
        TableView,
        FilterPrime,
    },
    data(){
        return {
            selected: null,    
            fields: [
                { key: "name", label: "Lieu", sortable: true},
                { key: "display_name", label: "Description du Lieu", sortable: false},
                { key: "location_type", label: "Type de Lieu", sortable: false},   
                { key: "state", label: "Statut", sortable: false} 
            ],
            totalRows: 1,
            currentPage: 1,
            perPage: 2,
            filterOn: ['name'],
            filter: null,
            fixed: false,
            theAnotherPage: "/creation-rendez-vous/gestion-theme"
        }
    },
    computed: {
        ...mapGetters("admin",['getPerPage', 'getPageOptions', 
                'getTableFilterOn', 'getSearchLocationText', 'getTableRow']),
        ...mapGetters("appointmentLocation", ["getAppointmentLocations", "getFilterStatut", "getFilterSearchText"]),
        rows(): number {
            return this.getTableRow.location
        },
        filteredItems() {
            const filtered = this.getAppointmentLocations.filter((item) => {
                return (
                    (!this.getFilterStatut || item.state === this.getFilterStatut) &&
                    (
                        !this.getFilterSearchText || this.getFilterSearchText.trim() === '' ||
                        item.name.toLowerCase().includes(this.getFilterSearchText) ||
                        item.display_name.toLowerCase().includes(this.getFilterSearchText)
                    )
                );
            });
            return filtered;
        },
    },
    methods: {
        ...mapActions('appointmentLocation', ['retrieveAppointmentLocationForUser', "setFilterStatut", "setFilterSearchText"]),
        ...mapActions("admin",['setTableRow']),
        filtered(filteredItems: Record<string, unknown>[]) {
            this.setTableRow({...this.getTableRow, location: filteredItems.length})
        },
        filterStateSet(value: string){
            this.setFilterStatut(value)
        },
        filterSearchText(value: string){
            this.setFilterSearchText(value)
        }
    },
    mounted() {
        // Set the initial number of items
        this.retrieveAppointmentLocationForUser().then(() => this.setTableRow({...this.getTableRow, location: this.getAppointmentLocations.length}))
    },
})
