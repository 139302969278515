































































































































import { Vue } from "vue-property-decorator";
import FormInputInline from "@/components/form-element/form-input/form-input-inline.vue";
import FormSelectInline from "@/components/form-element/form-select/form-select-inline.vue";
import ScheduleTemplate from "@/components/schedule-template/schedule-template.vue";
import { mapActions, mapGetters } from "vuex";
import moment from 'moment/moment';
import { appMixin } from '@/mixins/app.mixin';
import { ScheduleSpan } from "@/models/schedule/scheduleModel";
import { RessourceStateEnum } from "@/common/api.enums";

export default Vue.extend({
    name: "PatternManage",
    components: {
        FormInputInline,
        FormSelectInline,
        ScheduleTemplate
    },
    mixins: [appMixin],
    props: [],
    data() {
      return {
        form: {
            label: '',
            description: '',
            purposeSelected: '',
            locationSelected: '',
            categorySelected: '',
            subCategorySelected: '',
            serviceSelected: '',
            codification: ''
        },
        validate: {
            label: "",
            description: "",
            purposeSelected: "",
            locationSelected: "",
            categorySelected: "",
            subCategorySelected: "",
            codification: ''
        },
        categoryOptions: new Array<{value: string | null | undefined, text: string | undefined}>(),
        subCategoryOptions: new Array<{value: string | null | undefined, text: string | undefined}>(),
        locationOptions: new Array<{value: string | null | undefined, text: string | undefined}>(),
        disabled: true,
        deleteBtn: true,
        statusOptions: [
            { value: 'statut_1', text: 'Publie' },
            { value: 'statut_2', text: 'Supprime' },
            { value: 'statut_3', text: 'Brouillon' },
        ],
        data: {}
      }
    },
    methods: {
        ...mapActions("scheduleTemplate",['updateScheduleTemplate', 'deleteScheduleTemplate']),
        ...mapActions("appointmentLocation",['retrieveAppointmentLocationForUser', 'setAppointmentLocationSelectBox']),
        ...mapActions("businessPurpose", ['retrieveBusinessPurpose', 'setBusinessPurposeOverride']),
        ...mapActions("businessCategory", ['retriveBusinessCategory', 'setBusinessCategoryOverride', 'setBusinessCategories']),
        ...mapActions("admin", ['setScheduleTemplateModification', 'setTemplateFlashMessage']),
        onDelete(event: Event){
            event.preventDefault()
            this.deleteScheduleTemplate(this.getScheduleTemplate.template_id)
            this.$router.push('/gestion-rendez-vous/gestion-motifs')
        },

      onLabelSet(value: string) {
            this.form.label = value
        },

        onDescriptionSet(value: string) {
            this.form.description = value
        },

        onLocationSet(value: string) {
            this.form.locationSelected = value
        },

        onCodificationSet(value: string) {
            this.form.codification = value
        },

        onServiceSet(value: string) {
            this.form.serviceSelected = value
        },

        onPurposeSet(value: string) {
            this.form.purposeSelected = value
            this.retrieveBusinessPurpose(value).then( () => this.categoryOptions = this.categorySelectBox(this.getBusinessPurpose.categories))
        },

        onCategorySet(value: string) {
            this.form.categorySelected = value
            this.retriveBusinessCategory(value).then( () => this.subCategoryOptions = this.subCategorySelectBox(this.getBusinessCategory.sub_categories))
        },

        onSubCategorySet(value: string) {
            this.form.subCategorySelected = value
        },

        convertToJson(payload: Map<string, ScheduleSpan[]>): Record<string, unknown> {
            const result = Object.fromEntries(this.scheduleDaysValidation(payload))
            return this.data = result
        },

        validation(): boolean{
            let validationState = true
            if(this.form.label === '') {
                this.validate.label = 'false'
                validationState = false
            }else{
                this.validate.label = ''
            }
            if(this.form.purposeSelected === '') {
                this.validate.purposeSelected = 'false'
                validationState = false
            }else{
                this.validate.purposeSelected = ''
            }
            if(this.form.locationSelected === '') {
                this.validate.locationSelected = 'false'
                validationState = false
            }else{
                this.validate.locationSelected = ''
            }
            if(this.form.codification === '') {
                this.validate.codification = 'false'
                validationState = false
            }else{
                this.validate.codification = ''
            }
            if(this.form.categorySelected === '') {
                this.validate.categorySelected = 'false'
                validationState = false
            }else{
                this.validate.categorySelected = ''
            }
            if(this.form.subCategorySelected === '') {
                this.validate.subCategorySelected = 'false'
                validationState = false
            }else{
                this.validate.subCategorySelected = ''
            }
            return validationState
            },

        scheduleDaysValidation(payload: Map<string, ScheduleSpan[]>): Map<string, ScheduleSpan[]> {
            for (let entry of payload.entries()) {
                entry[1] = entry[1].filter(elt => {
                if (elt.start_time === '' || elt.end_time === '') {
                    return false
                }
                return true
                })
                if(entry[1].length === 1) {
                payload.set(entry[0], entry[1])
                }
                if(entry[1].length === 0) {
                payload.delete(entry[0])
                }
            }
            return payload
        },

        goToAnotherPage(page: string) {
            this.$router.push(page);
        },

        onRegister(event: Event) {
            event.preventDefault()
                this.updateScheduleTemplate({
                metadata: {
                    title: this.form.label,
                    description: this.form.description,
                    business_reference: {
                        purpose_id: this.form.purposeSelected,
                        bp_name: this.getBusinessPurpose.name,
                        category_id: this.form.categorySelected,
                        category_name: this.getBusinessCategory.name,
                        sub_category_name: this.form.subCategorySelected,
                        location_id: this.form.locationSelected,
                        business_id_prefix: this.form.codification
                    },
                    participants: [
                        {
                            user_name: "zaffoh",
                            full_name: "Zakari AFFOH",
                            own_ship: true,
                            meta_data: {
                                ref1: "Test Model"
                            }
                        }
                    ]
                },
                schedule_start_time: moment().format('YYYY-MM-DD')+'T00:00:00.0',
                schedule_end_time: moment().add(7, 'd').format('YYYY-MM-DD')+'T23:59:59.0',
                schedule_days: this.convertToJson(this.getScheduleDays),
                access_control: {
                    write: {
                        groups: ["TEST"]
                    }
                },
                template_id: this.getScheduleTemplate.template_id,
                state: RessourceStateEnum.Draft
            }).then(() => {
                    this.setTemplateFlashMessage({showMessage: true, message: 'Félicitation! Votre motif de rendez-vous a ete bien modifier et enregistrer.'})
                    this.goToAnotherPage('/gestion-rendez-vous/gestion-motifs')
                })
        },

        onPublished(event: Event) {
            event.preventDefault()
            if(this.validation() === true){
                this.updateScheduleTemplate({
                metadata: {
                    title: this.form.label,
                    description: this.form.description,
                    business_reference: {
                        purpose_id: this.form.purposeSelected,
                        bp_name: this.getBusinessPurpose.name,
                        category_id: this.form.categorySelected,
                        category_name: this.getBusinessCategory.name,
                        sub_category_name: this.form.subCategorySelected,
                        location_id: this.form.locationSelected,
                        business_id_prefix: this.form.codification
                    },
                    participants: [
                        {
                            user_name: "zaffoh",
                            full_name: "Zakari AFFOH",
                            own_ship: true,
                            meta_data: {
                                ref1: "Test Model"
                            }
                        }
                    ]
                },
                schedule_start_time: moment().format('YYYY-MM-DD')+'T00:00:00.0',
                schedule_end_time: moment().add(7, 'd').format('YYYY-MM-DD')+'T23:59:59.0',
                schedule_days: this.convertToJson(this.getScheduleDays),
                access_control: {
                    write: {
                        groups: ["TEST"]
                    }
                },
                template_id: this.getScheduleTemplate.template_id,
                state: RessourceStateEnum.Published
            }).then(() => {
                    this.setTemplateFlashMessage({showMessage: true, message: 'Félicitation! Votre motif de rendez-vous a ete bien publié.'})
                    this.goToAnotherPage('/gestion-rendez-vous/gestion-motifs')
                })
            }
        },

        initializeValues() {
            this.form.label = this.getScheduleTemplate.metadata.title
            this.form.description = this.getScheduleTemplate.metadata.description
            this.form.locationSelected = this.getScheduleTemplate.metadata.business_reference.location_id
            this.form.codification = this.getScheduleTemplate.metadata.business_reference.business_id_prefix
            this.locationOptions = this.locationSelectBox(this.getAppointmentLocations)
            this.form.purposeSelected = this.getScheduleTemplate.metadata.business_reference.purpose_id
            this.setBusinessPurposeOverride(this.form.purposeSelected)
            this.form.categorySelected = this.getScheduleTemplate.metadata.business_reference.category_id
            this.setBusinessCategories(this.getBusinessPurpose.categories)
            this.setBusinessCategoryOverride(this.form.categorySelected)
            this.form.subCategorySelected = this.getScheduleTemplate.metadata.business_reference.sub_category_name
            this.categoryOptions = this.categorySelectBox(this.getBusinessPurpose.categories)
            this.subCategoryOptions = this.subCategorySelectBox(this.getBusinessCategory.sub_categories)
        },

        disableOrNot(){
            this.setScheduleTemplateModification(!this.getScheduleTemplateModification)
        },

        showDeleteBtnOrNot(): boolean {
            if(this.getScheduleTemplate.state === RessourceStateEnum.Draft){
                return false
            }else{
                return true
            }
        }
    },
    computed: {
        ...mapGetters("scheduleTemplate",['getScheduleDays', 'getScheduleStartDate', 'getScheduleEndDate', 'getScheduleTemplate']),
        ...mapGetters("appointmentLocation", ['getAppointmentLocationSelectBox', 'getAppointmentLocations']),
        ...mapGetters("businessPurpose", ['getBusinessPurposeSelectBox', 'getBusinessPurpose']),
        ...mapGetters("businessCategory", ['getBusinessCategory', 'getBusinessCategories']),
        ...mapGetters("admin", ['getScheduleTemplateModification'])
    },
    beforeMount() {
        this.initializeValues()
        this.setScheduleTemplateModification(true)
    }
})
