export const layoutMixin = {
  computed: {
  },
  data() {
    return {
      sidebarItems: [
        {
          id: '/program_cal',
          link: '/program_cal',
          titleMenu: 'Tableau de bord',
          icon: 'dashboard' 
        },
        {
          id: '/gestion-rendez-vous',
          titleMenu: 'Gestion des Rendez-Vous',
          link: '/gestion-rendez-vous',
          icon: 'event_available',
          subMenus: [
            {
              id: '/gestion-rendez-vous/themes-categories-lieux',
              header: 'Configuration générale',
              title: 'Configuration générale',
              link: '/gestion-rendez-vous/themes-categories-lieux',
            },
            {
              id: '/gestion-rendez-vous/gestion-motifs',
              header: 'Gestion des Motifs',
              title: 'Gestion des Motifs',
              link: '/gestion-rendez-vous/gestion-motifs',
            },
          ],
        },
        {
          id: '/edition-rendez-vous',
          link: '/edition-rendez-vous',
          titleMenu: 'Edition des rendez-vous',
          icon: 'edit_calendar',
          subMenus: [
            {
              id: '/edition-rendez-vous/edition-motifs-de-rendez-vous',
              header: 'Motifs de rendez-vous',
              title: 'Motifs de rendez-vous',
              link: '/edition-rendez-vous/edition-motifs-de-rendez-vous',
            },
            {
              id: '/edition-rendez-vous/generation-des-rendez-vous',
              header: 'Génération des rendez-vous',
              title: 'Génération des rendez-vous',
              link: '/edition-rendez-vous/generation-des-rendez-vous',
            },
          ],
        }
      ],
    };
  },
  methods: {
  },
};