


























import { Vue } from "vue-property-decorator";
import PurposesTab from '@/components/tabs-component/purposes-tab.vue';
import CategoriesTab from '@/components/tabs-component/categories-tab.vue';
import LocationsTab from '@/components/tabs-component/locations-tab.vue';
import ScheduleModal from '@/components/schedule-modal/schedule-modal.vue';
import { mapGetters } from "vuex";

export default Vue.extend({
    name: "ThemeCategoryRdv",

    components:{
        PurposesTab,
        ScheduleModal,
        CategoriesTab,
        LocationsTab
    },
    data(){
        return {

            pageTitle: "Themes et Categories de Rendez-Vous",
    
        }
    },
    computed: {
      ...mapGetters("admin",['getPerPage']),
    },
})
