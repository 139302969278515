











import { Vue } from "vue-property-decorator";
import NavbarApp from '@/components/layout/navbar-app/navbar-app.vue';
import { mapActions } from "vuex";

export default Vue.extend({
  name: "App",
  components: {
    NavbarApp,
  },
  methods: {
    ...mapActions("layout", ['setActiveSidebar']),
    ...mapActions("businessPurpose", ["retrieveBusinessPurposes"]),
    ...mapActions("appointmentLocation", ['retrieveAppointmentLocationForUser']),
    ...mapActions("scheduleTemplate", ['retrieveScheduleTemplates']),
    ...mapActions("organization", ['loadOrganizations', 'secondTokens']),
  }, 
  mounted(){
    this.retrieveAppointmentLocationForUser();
    this.retrieveScheduleTemplates();
    this.retrieveBusinessPurposes();
    this.loadOrganizations();
  }
});
