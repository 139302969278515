





















import Vue from 'vue'
import moment from 'moment/moment';
import { mapGetters, mapActions } from 'vuex' 
import { ScheduleTemplateCreateDto } from '@/dto/schedule/scheduleDto';

export default Vue.extend({
  name: 'Recurence',
  props:[
    'start'
  ],
  data() {
        const now = new Date()
        const afterOneMonth = new Date(now)
        afterOneMonth.setMonth(now.getMonth() + 1)
        return {
            form: {
                dateStart: this.start,
                dateEnd: '',
                selectedPeriode: 4,
                serviceSelected: '',
                userSelected: '',
                prefix: ''
            },
            recurrencePeriode: [
                {value: 0, text: 'Selectionner une Periode'},
                {value: 1, text: 'Une semaine'},
                {value: 2, text: 'Deux semaines'},
                {value: 3, text: 'Trois semaines'},
                {value: 4, text: 'Un mois'},
                {value: 5, text: 'Trois mois'},
                {value: 6, text: 'Six mois'},
                {value: 7, text: 'Un an'},
                {value: 8, text: 'Personnalisé'}
            ],
        }
    },
    computed: {
        ...mapGetters("scheduleTemplate", ['getScheduleTemplate']),
    },
    methods: {
        ...mapActions("scheduleTemplate", ['createAppointmentScheduleTemplate']),
        onSubmit(){
            let scheduleTemplate: ScheduleTemplateCreateDto = this.getScheduleTemplate
            scheduleTemplate.schedule_start_time = this.form.dateStart+'T00:00:00.0'
            scheduleTemplate.schedule_end_time = this.form.dateEnd+'T23:59:59.0'
            this.createAppointmentScheduleTemplate({
                metadata: scheduleTemplate.metadata,
                schedule_start_time: scheduleTemplate.schedule_start_time,
                schedule_end_time: scheduleTemplate.schedule_end_time,
                schedule_days: scheduleTemplate.schedule_days,
                access_control: scheduleTemplate.access_control
            })
            this.$root.$emit('bv::hide::modal', 'recurrenceModal', '#btnSubmit')
        },
        dateInitialization() {
            const now = new Date()
            const afterOneMonth = new Date(now)
            afterOneMonth.setMonth(now.getMonth() + 1)
            this.form.dateStart = this.formattingDate(now)
            this.form.dateEnd = this.formattingDate(afterOneMonth)
        },
        formattingDate(value: Date): string{
            return moment(value).format('YYYY-MM-DD');
        },
        onDateStartSet(value: string){
            this.form.dateStart = moment(value).format('YYYY-MM-DD')
            this.onPeriodeSelected(this.form.selectedPeriode)
            this.$emit('dateStartChange', this.form.dateStart)
        },
        onDateEndSet(value: string){
            this.form.dateEnd = moment(value).format('YYYY-MM-DD')
            this.$emit('dateEndChange', this.form.dateEnd)
        },
        makeToast(variant: string, message: string, title: string, delay: number) {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant,
                solid: true,
                autoHideDelay: delay
            })
        },
        onPeriodeSelected(value: number) {
            let start = new  Date()
            if(this.form.dateStart !== ''){
                start = new Date(this.form.dateStart)
            }
            const end = new Date(start)
            this.form.selectedPeriode = value
            this.form.dateStart = this.formattingDate(start)
            this.$emit('dateStartChange', this.form.dateStart)
            switch (value) {
                case 1: {
                    end.setDate(start.getDate() + 7)
                    this.form.dateEnd = this.formattingDate(end)
                    this.$emit('dateEndChange', this.form.dateEnd)
                }break
                case 2: {
                    end.setDate(start.getDate() + 14)
                    this.form.dateEnd = this.formattingDate(end)
                    this.$emit('dateEndChange', this.form.dateEnd)
                }break
                case 3: {
                    end.setDate(start.getDate() + 21)
                    this.form.dateEnd = this.formattingDate(end)
                    this.$emit('dateEndChange', this.form.dateEnd)
                }break
                case 4: {
                    end.setMonth(start.getMonth() + 1)
                    this.form.dateEnd = this.formattingDate(end)
                    this.$emit('dateEndChange', this.form.dateEnd)
                }break
                case 5: { 
                    end.setMonth(start.getMonth() + 3)
                    this.form.dateEnd = this.formattingDate(end)
                    this.$emit('dateEndChange', this.form.dateEnd)
                }break
                case 6: {
                    end.setMonth(start.getMonth() + 6)
                    this.form.dateEnd = this.formattingDate(end)
                    this.$emit('dateEndChange', this.form.dateEnd)
                }break
                case 7: {
                    end.setFullYear(start.getFullYear() + 1)
                    this.form.dateEnd = this.formattingDate(end)
                    this.$emit('dateEndChange', this.form.dateEnd)
                }break
                case 8: {
                    this.$emit('dateEndChange', this.form.dateEnd)
                }break
                default: {
                    end.setMonth(start.getMonth() + 1)
                    this.form.dateEnd = this.formattingDate(end)
                    this.$emit('dateEndChange', this.form.dateEnd)
                }
            }    
        }
    },
    watch: {
        start() {
            if(this.start){
                this.dateStart = this.start
            }
        },
    },
    mounted() {
        this.dateInitialization()
    }
})   
