import { RessourceStateEnum } from '@/common/api.enums';
import { Utils } from '@/common/utils';
import { BusinessPurposeUpdate } from '@/dto/metadata/metadataDto';
import { BusinessCategoryModel, BusinessPurpose } from '@/models/metadata/businessMetadata';
import { MutationTree } from 'vuex';
import { businessPurposeState } from "./types";



export const mutations: MutationTree<businessPurposeState> = {

    setBusinessPurpose(state, payload: BusinessPurpose) {
        state.businessPurpose = payload
    },

    setBusinessPurposeOverride(state, payload: string) {
        state.businessPurpose = state.businessPurposes?.find(({purpose_id}) => purpose_id === payload)
    },

    setBusinessPurposes(state, payload: Array<BusinessPurpose>) {
        state.businessPurposes = payload
        state.businessPurposesPublished = payload.filter((businessPurpose) => businessPurpose.state === RessourceStateEnum.Published)
        state.businessPurposeSelectBox = Utils.businessSelectBox(state.businessPurposesPublished)
    },

    setBusinessPurposeSelectBox(state, payload: []) {
        state.businessPurposeSelectBox = payload
    },

    addBusinessPurposeSelectBox(state, payload: {value: string, text: string}) {
        state.businessPurposeSelectBox.push(payload)
    },

    addBusinessPurpose(state, payload: BusinessPurpose) {
        state.businessPurposes?.push(payload)
        if (payload.state === RessourceStateEnum.Published) {
            state.businessPurposesPublished?.push(payload)
        }
    },

    updateBusinessPurpose(state, payload: BusinessPurposeUpdate) {
        const updatedPurpose = state.businessPurposes?.find(({ purpose_id }) => purpose_id === payload.purpose_id)
        if (updatedPurpose !== undefined) {
            const purpose: BusinessPurpose = {
                ...updatedPurpose,
                name: payload.name,
                description: payload.description,
                organization_name: payload.organization_name,
                organization_unit: payload.organization_unit,
                state: payload.state
            }
            state.businessPurposes?.splice(state.businessPurposes?.indexOf(updatedPurpose), 1, purpose)
            state.businessPurposesPublished = state.businessPurposes?.filter((businessPurpose) => businessPurpose.state === RessourceStateEnum.Published)
            state.businessPurposeSelectBox = Utils.businessSelectBox(state.businessPurposesPublished)
        }
    },

    deleteBusinessPurpose(state, payload: string) {
        state.businessPurposes =  state.businessPurposes?.filter(
            (businessPurpose: BusinessPurpose) => {
                return businessPurpose.purpose_id !== payload
            }) 

        state.businessPurposesPublished = state.businessPurposes?.filter((businessPurpose) => businessPurpose.state === RessourceStateEnum.Published)
        state.businessPurposeSelectBox = Utils.businessSelectBox(state.businessPurposesPublished)   
    },

    setCategoryPurposes(state, payload: Array<BusinessPurpose>) {
        state.categoryPurposes = payload
    },

    setBusinessCategoryTable(state, payload: BusinessPurpose[]) {
        state.businessCategoryTable = [];
        payload?.forEach((purpose: BusinessPurpose) => {
            if (purpose.categories?.length !== 0 &&
                purpose.categories !== undefined) {
                    purpose.categories.forEach((category: BusinessCategoryModel) => {
                        state.businessCategoryTable?.push({
                            category_id: category.category_id,
                            name: category.name,
                            description: category.description,
                            sub_categories: category.sub_categories,
                            state: category.state,
                            purpose: purpose.name,
                            purpose_id: purpose.purpose_id
                        })
                    });
            }
        });
        state.businessCategoriesPublished = state.businessCategoryTable.filter((businessPurpose) => businessPurpose.state === RessourceStateEnum.Published)
    },

    filterByPurpose(state, payload: string) {
        const categoryPurposes = state.categoryPurposes.filter((purpose) => purpose.purpose_id === payload)
        state.businessCategoryTable = [];
        categoryPurposes?.forEach((purpose: BusinessPurpose) => {
            if (purpose.categories?.length !== 0 &&
                    purpose.categories !== undefined) {
                        purpose.categories.forEach((category: BusinessCategoryModel) => {
                        state.businessCategoryTable?.push({
                            category_id: category.category_id,
                            name: category.name,
                            description: category.description,
                            sub_categories: category.sub_categories,
                            state: category.state,
                            purpose: purpose.name,
                            purpose_id: purpose.purpose_id
                        })
                    });
            }
        });
    },

    setFilterStatut(state, payload: string | null){
        state.filterStatut = payload
    },

    setFilterSearchText(state, payload: string | null){
        state.filterSearchText = payload
    },

};