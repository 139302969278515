import { ActionTree } from 'vuex';
import { businessPurposeState } from "./types";
import { RootState } from "@/store/types"
import { BusinessPurpose } from '@/models/metadata/businessMetadata';
import { BusinessPurposeCreate, BusinessPurposeUpdate } from '@/dto/metadata/metadataDto';
import { BusinessPurposeService } from '@/services/businessPurpose.service';
import { Utils } from '@/common/utils';
import { RessourceStateEnum } from '@/common/api.enums';
import { Vue } from "vue-property-decorator";
import { TYPE } from "vue-toastification";

export  const actions: ActionTree<businessPurposeState, RootState> = {

    setBusinessPurpose(context, payload: BusinessPurpose) {
        context.commit("setBusinessPurpose", payload)
    },

    setBusinessPurposeOverride(context, payload: string) {
        context.commit("setBusinessPurposeOverride", payload)
    },

    setBusinessPurposeSelectBox(context, payload: Array<BusinessPurpose>) {
        context.commit("setBusinessPurposeSelectBox", Utils.businessSelectBox(payload))
    },

    filterByPurpose(context, payload: string) {
        if (payload !== '') {
            context.commit("filterByPurpose", payload)
        } else {
            context.dispatch("retrieveBusinessPurposes")
        }
    },

    createBusinessPurpose(context, payload: BusinessPurposeCreate) { 
        return BusinessPurposeService.getInstance(this.getters.getUser.access.access_token).createBusnessPurpose(payload)
            .then(response => {
                if (response.data) {
                    context.commit("addBusinessPurpose", response.data)
                    if(response.data.state === RessourceStateEnum.Published)
                        context.commit("addBusinessPurposeSelectBox", { value: response.data.purpose_id, text: response.data.name })
                    Vue.$toast("Thème créé avec succès!");
                }
                if (response.apiError) {
                    Vue.$toast("Erreur lors de la creation du thème!", { type: TYPE.ERROR });
                }
            })
    },

    updateBusinessPurpose(context, payload: BusinessPurposeUpdate) {
        return BusinessPurposeService.getInstance(this.getters.getUser.access.access_token).updateBusinessPurpose(payload)
            .then((response) => {
                //I do this because response.data === ""
                if (response.apiError) {
                    Vue.$toast("Erreur lors de la modification du thème!", { type: TYPE.ERROR });
                } else {
                    Vue.$toast("Thème modifié avec succès!");
                    context.commit('updateBusinessPurpose', payload)
                }
            })
    },

    retrieveBusinessPurpose(context, payload: string) {
        return BusinessPurposeService.getInstance(this.getters.getUser.access.access_token).loadBusinessPurpose(payload)
            .then(response => {
                if (response.data) {
                    context.commit("setBusinessPurpose", response.data)
                }
                if (response.apiError) {
                    Vue.$toast("Erreur lors du chargement du thème!", { type: TYPE.ERROR });
                }
            })
    },

    retrieveBusinessPurposes(context) {
        return BusinessPurposeService.getInstance(this.getters.getUser.access.access_token).loadBusinessPurposes()
            .then(response => {
                if (response.data) {
                    context.commit("setCategoryPurposes", response.data)
                    context.commit("setBusinessCategoryTable", response.data)
                    context.commit("setBusinessPurposes", response.data)
                }
                if (response.apiError) {
                    Vue.$toast("Erreur lors du chargement des thèmes!", { type: TYPE.ERROR });
                }
            })
    },

    deleteBusinessPurpose(context, payload: string) {
        return BusinessPurposeService.getInstance(this.getters.getUser.access.access_token).deleteBusinessPurpose(payload)
            .then(response => {
                if (response.apiError) {
                    Vue.$toast("Erreur: Désolé, ce thème ne peut être supprimé car il dispose de rendez-vous!", { type: TYPE.ERROR });
                }else{
                    Vue.$toast("Thème supprimé avec succès!");
                    context.commit('deleteBusinessPurpose', payload)
                }  
            })
    },

    setFilterStatut(context, payload: string | null){
        context.commit('setFilterStatut', payload)
    },

    setFilterSearchText(context, payload: string | null){
        context.commit('setFilterSearchText', payload)
    },

}
