import { GetterTree } from "vuex";
import { RootState } from "@/store/types"
import { businessCategoryState } from "./types";
import { BusinessCategoryModel, BusinessPurpose } from "@/models/metadata/businessMetadata";


export const getters: GetterTree<businessCategoryState, RootState> = {

    getBusinessCategory(state): BusinessCategoryModel | undefined{
        return state.businessCategory
    },

    getBusinessCategories(state): Array<BusinessCategoryModel> | undefined {
        return state.businessCategories
    },

    getBusinessCategoryTable(state): Array<BusinessCategoryModel> {
        return state.businessCategoryTable
    },

    getCategoryPurposes(state): Array<BusinessPurpose>{
        return state.categoryPurposes
    },

    getBusinessCategoriesPublished(state): Array<BusinessCategoryModel> {
        return state.businessCategoriesPublished
    },

    getFilterStatut(state): string | null{
        return state.filterStatut
    },

    getFilterSearchText(state): string | null{
        return state.filterSearchText
    },

    getFilterPurposeSearch(state): string | null{
        return state.filterPurposeSearch
    }

}