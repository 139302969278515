import { GetterTree } from "vuex";
import { RootState } from "@/store/types"
import { scheduleTemplateState } from "./types";
import { ScheduleAppointment, ScheduleSpan } from "@/models/schedule/scheduleModel";
import { Duration } from "@/models/schedule/duration";
import { AppointmentModel, AppointmentScheduleTemplate, AppointmentWithSummary, ProgramAppointmentSummary } from "@/models/appointment/appointmentModel";
import { AppointmentCreateDto } from "@/dto/appointment/appointmentDto";


export const getters: GetterTree<scheduleTemplateState, RootState> = {

    getScheduleTemplate(state): AppointmentScheduleTemplate | undefined{
        return state.scheduleTemplate
    },

    getScheduleTemplates(state): Array<AppointmentScheduleTemplate> | undefined{
        return state.scheduleTemplates
    },

    getScheduleDays(state): Map<string, ScheduleSpan[]> | undefined{
        return state.scheduleDays
    },

    getScheduleDuration(state): Duration | undefined {
        return state.scheduleDuration
    },

    getScheduleGraceTime(state): Duration | undefined {
        return state.scheduleGraceTime
    },

    getScheduleExcludeFreeday(state): boolean {
        return state.scheduleExcludeFreeday
    },

    getScheduleStartDate(state): string {
        return state.scheduleStartDate
    },

    getScheduleEndDate(state): string {
        return state.scheduleEndDate
    },

    getScheduleAppointment(state): ScheduleAppointment | undefined {
        return state.scheduleAppointment
    },

    getScheduleAppointments(state): Array<ScheduleAppointment> | undefined {
        return state.scheduleAppointments
    },

    getAppointment(state): AppointmentModel | undefined {
        return state.appointment
    },

    getAppointments(state): Array<AppointmentModel> | undefined {
        return state.appointments
    },

    getAppointments_(state): AppointmentCreateDto[] | undefined{
        return state.appointments_
    },

    getProgrammingAppointments(state): Array<ProgramAppointmentSummary> {
        return state.programmingAppointments
    },

    getAppointmentWithSummary(state): AppointmentWithSummary | undefined {
        return state.appointmentWithSummary
    },

    getProgrammingAppointment(state): ProgramAppointmentSummary | undefined {
        return state.programmingAppointment
    },

    getProgrammingAppointmentStat(state): { cancelled: number, assigned: number, published: number } {
        return state.programmingAppointmentStat
    },

    getFilterStatut(state): string | null{
        return state.filterStatut
    },

    getFilterSearchText(state): string | null{
        return state.filterSearchText
    },

    getFilterPurposeSearch(state): string | null{
        return state.filterPurposeSearch
    }

}