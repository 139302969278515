


























































































import Vue from "vue";
import FormInput from "@/components/form-element/form-input/form-input.vue";
import moment from "moment/moment";
import { mapActions, mapGetters } from "vuex";
import { appMixin } from '@/mixins/app.mixin';
import { ParticipantModel } from "@/models/participant/participantModel";
export default Vue.extend({
  name: "AppointmentEventUpdate",
  components: {
    FormInput,
  },
  mixins: [appMixin],
  props: [],
  data() {
    return {
      form: {
        startTime: "",
        endTime: "",
        startDate: "",
        endDate: "",
        participant: [{}],
      },
      eventSelectedId: "",
      participants: [
        {
          user_name: "Derick",
          ou: undefined,
          full_name: "SEDZRO Yao Derick",
          own_ship: false,
          is_business_unit: undefined,
          meta_data: undefined,
        },
        {
          user_name: "Nuno",
          ou: undefined,
          full_name: "Nuno Mendes",
          own_ship: false,
          is_business_unit: undefined,
          meta_data: undefined,
        },
        {
          user_name: "Justin",
          ou: undefined,
          full_name: "Justin Adjassem",
          own_ship: false,
          is_business_unit: undefined,
          meta_data: undefined,
        },
        {
          user_name: "Lucas",
          ou: undefined,
          full_name: "Kouami Lucas",
          own_ship: false,
          is_business_unit: undefined,
          meta_data: undefined,
        },
        {
          user_name: "Erick",
          ou: undefined,
          full_name: "Drumon Erick",
          own_ship: false,
          is_business_unit: undefined,
          meta_data: undefined,
        },
        {
          user_name: "Sergios",
          ou: undefined,
          full_name: "Sergio Dos Ramos",
          own_ship: false,
          is_business_unit: undefined,
          meta_data: undefined,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("scheduleTemplate", ["getScheduleAppointment"]),
    startdate(): string {
      this.onAppointmentDateStartSet(
        moment(this.getScheduleAppointment.start).format("YYYY-MM-DD")
      );
      return moment(this.getScheduleAppointment.start).format("YYYY-MM-DD");
    },
    enddate(): string {
      this.onAppointmentDateEndSet(
        moment(this.getScheduleAppointment.end).format("YYYY-MM-DD")
      );
      return moment(this.getScheduleAppointment.end).format("YYYY-MM-DD");
    },
    starttime(): string {
      this.onAppointmentTimeStartSet(
        moment(this.getScheduleAppointment.start).format("HH:mm")
      );
      return moment(this.getScheduleAppointment.start).format("HH:mm:ss");
    },
    endtime(): string {
      this.onAppointmentTimeEndSet(
        moment(this.getScheduleAppointment.end).format("HH:mm")
      );
      return moment(this.getScheduleAppointment.end).format("HH:mm:ss");
    },
    participantSelected(): ParticipantModel {
      this.onParticipantSet(
        this.getScheduleAppointment.extendedProps.participant[0]
      );
      return this.getScheduleAppointment.extendedProps.participant[0];
    },
    participantSelectBox(): Array<{
      value: ParticipantModel | string;
      text: string | undefined;
    }> {
      return this.participantSelectBox(this.participants);
    },
  },
  methods: {
    ...mapActions("scheduleTemplate", [
      "updateScheduleAppointments",
      "removeScheduleAppointment",
    ]),
    onAppointmentDateStartSet(value: string) {
      this.form.startDate = value;
    },
    onAppointmentDateEndSet(value: string) {
      this.form.endDate = value;
    },
    onAppointmentTimeStartSet(value: string) {
      this.form.startTime = value + ":00.0";
    },
    onAppointmentTimeEndSet(value: string) {
      this.form.endTime = value + ":00.0";
    },
    onParticipantSet(value: ParticipantModel) {
      this.form.participant[0] = {
        ...value,
        own_ship: true,
      };
    },
    onSubmit(event: Event) {
      event.preventDefault();
      this.updateScheduleAppointments({
        ...this.getScheduleAppointment,
        start: this.form.startDate + "T" + this.form.startTime,
        end: this.form.endDate + "T" + this.form.endTime,
        extendedProps: {
          participant: this.form.participant,
        },
      });
    },
    onDelete(event: Event) {
      event.preventDefault();
      this.removeScheduleAppointment(this.getScheduleAppointment);
      this.$root.$emit(
        "bv::hide::modal",
        "AppointmentEventUpdateModal",
        "#btnDelete"
      );
    },
  },
});
