import { ActionTree } from 'vuex';
import { businessCategoryState } from "./types";
import { RootState } from "@/store/types"
import { BusinessCategoryService } from '@/services/businessCategory.service';
import { BusinessCategoryCreate, BusinessCategoryUpdate } from '@/dto/metadata/metadataDto';
import { BusinessCategoryModel } from '@/models/metadata/businessMetadata';
import { BusinessPurposeService } from '@/services/businessPurpose.service';
import { Vue } from "vue-property-decorator";
import { TYPE } from "vue-toastification";

export  const actions: ActionTree<businessCategoryState, RootState> = {

    setBusinessCategory(context, payload: BusinessCategoryModel) {
        context.commit("setBusinessCategory", payload)
    },

    setBusinessCategories(context, payload: Array<BusinessCategoryModel>) {
        context.commit("setBusinessCategories", payload)
    },

    setBusinessCategoryOverride(context, payload: string) {
        context.commit("setBusinessCategoryOverride", payload)
    },

    setBusinessCategoryTable(context) {
        return BusinessPurposeService.getInstance(this.getters.getUser.access.access_token).loadBusinessPurposes()
            .then(value => {
                if (value.data) {
                    context.commit("setCategoryPurposes", value.data)
                    context.commit("setBusinessCategoryTable", value.data)
                }
            })
            .catch(error => {
                console.log('error')
            })
    },

    filterByPurpose(context, payload: string) {
        if (payload !== '') {
            context.commit("filterByPurpose", payload)
        } else {
            context.dispatch("setBusinessCategoryTable")
        }
    },

    createBusinessCategory(context, payload: BusinessCategoryCreate) {
        
        return BusinessCategoryService.getInstance(this.getters.getUser.access.access_token).createBusinessCategory(payload)
            .then(response => {
                if (response.data) {
                    Vue.$toast("Catégorie créé avec succès!");
                    context.commit("addBusinessCategory", response.data)
                }
                if (response.apiError) {
                    Vue.$toast("Erreur lors de la creation de la catégorie!", { type: TYPE.ERROR });
                }
            })
        
    },

    updateBusinessCategory(context, payload: BusinessCategoryUpdate) {
        return BusinessCategoryService.getInstance(this.getters.getUser.access.access_token).updateBusinessCategory(payload)
            .then((response) => {
                if (response.apiError) {
                    Vue.$toast("Erreur lors de la modification de la catégorie!", { type: TYPE.ERROR });
                } else {
                    Vue.$toast("Catégorie modifié avec succès!");
                }
            })
    },

    retriveBusinessCategory(context, payload: string) {
        return BusinessCategoryService.getInstance(this.getters.getUser.access.access_token).loadBusinessCategory(payload)
            .then(value => {
                if(value.data) {
                    context.commit('setBusinessCategory', value.data)
                }
            })
            .catch(error => {
                console.log('error')
            })
    },

    deleteBusinessCategory(context, payload: string) {
        return BusinessCategoryService.getInstance(this.getters.getUser.access.access_token).deleteBusinessCategory(payload)
            .then((response) => {
                if (response.apiError) {
                    Vue.$toast("Erreur: Désolé, cette catégorie ne peut être supprimée car elle dispose de rendez-vous!", { type: TYPE.ERROR });
                } else {
                    Vue.$toast("Catégorie supprimée avec succès!");
                }
            })
    },

    setFilterStatut(context, payload: string | null){
        context.commit('setFilterStatut', payload)
    },

    setFilterSearchText(context, payload: string | null){
        context.commit('setFilterSearchText', payload)
    },

    setFilterPurposeSearch(context, payload: string | null){
        context.commit('setFilterPurposeSearch', payload)
    },

}