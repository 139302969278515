




























import { BusinessPurpose } from "@/models/metadata/businessMetadata";
import { Vue } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import { LocationTypeEnum } from "@/common/api.enums";
export default Vue.extend({
  name: "TableView",
  props: [
    'tableItems', 'fixed', 'fields', 'filter',
    'currentPage', 'perPage', 'detailRow', 'rowSelection', 
    'theAnotherPage', 'tableConfig', 'filterIncludedFields'
  ],
  data() {
    return {
      rowSelected: "",
      transProps: {
        name: "flip-list",
      },
    };
  },
  computed: {
    ...mapGetters("admin", ['getPerPage', 'getCurrentPage']),
    ...mapGetters("businessPurpose", ['getBusinessPurposes', 'getBusinessPurpose']),
  },
  methods: {
    ...mapActions("businessPurpose", ["setBusinessPurpose"]),
    ...mapActions("businessCategory", ["setBusinessCategory"]),
    ...mapActions("appointmentLocation", ["setAppointmentLocation"]),
    ...mapActions("scheduleTemplate", ["setScheduleTemplate"]),
    onFiltered (filteredItems: Record<string, unknown>[]) {
      this.$emit('onFiltered', filteredItems)
    },
    onRowClicked(item: any) {
      if (item) {
        switch (this.detailRow) {
          case "detailPurpose":
            {
              this.setBusinessPurpose(item);
              this.$root.$emit(
                "bv::show::modal",
                "purposeManageModal",
                "#rowShow"
              );
            }
            break;
          case "detailCategory":
            {
              this.setBusinessCategory(item);
              this.$root.$emit(
                "bv::show::modal",
                "categoryManageModal",
                "#rowShow"
              );
            }
            break;
          case "detailLocation":
            {
              this.setAppointmentLocation(item);
              this.$root.$emit(
                "bv::show::modal",
                "locationManageModal",
                "#rowShow"
              );
            }
            break;
          case "detailScheduleTemplate":
            {
              this.setScheduleTemplate(item);
              this.goToAnotherPage(this.theAnotherPage);
            }
            break;
          default: {
            this.setBusinessPurpose(item);
            this.$root.$emit(
              "bv::show::modal",
              "purposeManageModal",
              "#rowShow"
            );
          }
        }
      }
    },
    goToAnotherPage(page: string) {
      this.$router.push(page);
    },
    stateFormating(value: string){
      if(value === LocationTypeEnum.Physical){
        return 'Physique'
      }
      return 'Virtuelle'
    }
  },
});
