import { AppointmentLocationCreate, AppointmentLocationUpdateDto } from "@/dto/appointment/appointmentDto"
import { ApiResponse } from "@/models/http/ApiResponse"
import { LocationModel } from "@/models/location/locationModel"
import { AxiosRequestConfig } from "axios"
import { HttpBaseService } from "./httpBase.service"
import { scheduleManagerApiUrl } from "@/app.config";


export class LocationService extends HttpBaseService{

    private static classInstance?: LocationService
    static apiBaseUrl = scheduleManagerApiUrl

    constructor(token:string) {
        super(token, LocationService.apiBaseUrl)
    }


    public static getInstance(token:string): LocationService {

        if(!this.classInstance) {
            this.classInstance = new LocationService(token)
        }

        return this.classInstance
    }


    public createAppointmentLocation(appointmentLocation: AppointmentLocationCreate) : Promise<ApiResponse<LocationModel>> {
        const apiResponse = new ApiResponse<LocationModel>()
        return this.instance.post('/admin/location/create', appointmentLocation)
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            }) 
            .catch(error => {
                apiResponse.apiError = error.response.data;
                return apiResponse;
            })
    }


    public updateAppointmentLocation(appointmentLocation: AppointmentLocationUpdateDto) : Promise<ApiResponse<LocationModel>> {
        const apiResponse = new ApiResponse<LocationModel>()
        return this.instance.put('/admin/location/update', appointmentLocation)
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            }) 
            .catch(error => {
                apiResponse.apiError = error.response.data;
                return apiResponse;
            })
    }


    public loadAppointmentLocation(locationId: string) : Promise<ApiResponse<LocationModel>>{
        const apiResponse = new ApiResponse<LocationModel>()
        const requestParams: AxiosRequestConfig = {
            params: {
                resource_id: locationId 
            }
        }

        return this.instance.get('/admin/location/:location_id', requestParams)
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            }) 
            .catch(error => {
                apiResponse.apiError = error.response.data;
                return apiResponse;
            })
    }


    public loadAppointmentLocationForUser() : Promise<ApiResponse<LocationModel[]>>{
        const apiResponse = new ApiResponse<LocationModel[]>()
        return this.instance.get('/admin/locations')
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            }) 
            .catch(error => {
                apiResponse.apiError = error.response.data;
                return apiResponse;
            })
    }

    public deleteAppointmentLocation(locationId: string) : Promise<ApiResponse<LocationModel>> {
        const apiResponse = new ApiResponse<LocationModel>()
        return this.instance.delete(`/admin/location/${locationId}`)
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            }) 
            .catch(error => {
                apiResponse.apiError = error.response.data;
                return apiResponse;
            })
    }

}
