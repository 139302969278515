






import { ScheduleSpan } from "@/models/schedule/scheduleModel";
import { Vue } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";
import moment from 'moment/moment';
export default Vue.extend({
    name: "ScheduleTimer",
    props: [
        'name',
        'journey',
        'startOrEnd',
        'disabled',
        'min',
        'max'
    ],
    data() {
        return {
            timeSelected: '',
            scheduleSpans: new Array<ScheduleSpan>(),
        };
    },
    computed: {
        ...mapGetters("scheduleTemplate",['getScheduleDays']),
    },
    methods: {
        ...mapActions("scheduleTemplate",['setScheduleDay']),
        onChange(value: string) {
            const MinTime = moment(this.min, 'hh:mm:ss')
            const MaxTime = moment(this.max, 'hh:mm:ss')
            const SelectedTime = moment(value+':00', 'hh:mm:ss')
            if(SelectedTime.isBetween(MinTime, MaxTime)) {
                this.timeSelected = value
                this.setScheduleDay(this.scheduleDayUpdate(this.timeSelected))
            }else{
                this.timeSelected = ''
            }
        },
        makeToast(variant: string, message: string, title: string, delay: number) {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant,
                solid: true,
                autoHideDelay: delay
            })
        },
        scheduleDayUpdate(value: string): Map<string, ScheduleSpan[]>{
            this.scheduleSpans = this.getScheduleDays.get(this.name)
            if(this.journey == 'morning') {
                if(this.startOrEnd == 'start') {
                    this.scheduleSpans[0].start_time = value+':00.0'
                }else{
                    this.scheduleSpans[0].end_time = value+':00.0'
                }
            }else{
                if(this.startOrEnd == 'start') {
                    this.scheduleSpans[1].start_time = value+':00.0'
                }else{
                    this.scheduleSpans[1].end_time = value+':00.0'
                }
            }
            let mapScheduleSpan = new Map<string, ScheduleSpan[]>()
            mapScheduleSpan.set(this.name, this.scheduleSpans)
            return mapScheduleSpan
        },
        initialization() {
            if(this.getScheduleDays.get(this.name)) {
                this.scheduleSpans = this.getScheduleDays.get(this.name)
                if(this.journey == 'morning'){
                    if( this.startOrEnd == 'start') {
                        this.timeSelected =  this.scheduleSpans[0].start_time.substring(0, this.scheduleSpans[0].start_time.length-3)  
                    }else{
                        this.timeSelected =  this.scheduleSpans[0].end_time.substring(0, this.scheduleSpans[0].end_time.length-3)
                    }
                }else{
                    if( this.startOrEnd == 'start') {
                        this.timeSelected =  this.scheduleSpans[1].start_time.substring(0, this.scheduleSpans[0].start_time.length-3)  
                    }else{
                        this.timeSelected =  this.scheduleSpans[1].end_time.substring(0, this.scheduleSpans[0].end_time.length-3)
                    }
                } 
            }
        }
    },
    beforeMount() {
        this.initialization()
    }
});
