import { BusinessPurpose } from "@/models/metadata/businessMetadata";
import { ApiResponse } from "@/models/http/ApiResponse";
import { HttpBaseService } from "./httpBase.service";
import { BusinessPurposeCreate, BusinessPurposeUpdate } from "@/dto/metadata/metadataDto";
import { scheduleManagerApiUrl } from "@/app.config";

export class BusinessPurposeService extends HttpBaseService{

    private static classInstance?: BusinessPurposeService
    static apiBaseUrl = scheduleManagerApiUrl

    constructor(token:string) {
        super(token, BusinessPurposeService.apiBaseUrl)
    }

    public static getInstance(token:string): BusinessPurposeService {

        if(!this.classInstance) {
            this.classInstance = new BusinessPurposeService(token)
        }

        return this.classInstance
    }


    public loadBusinessPurposes() : Promise<ApiResponse<BusinessPurpose[]>>{
        const apiResponse = new ApiResponse<BusinessPurpose[]>()
        return this.instance.get('/admin/metadata/business_purpose')
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            })
            .catch(error => {
                apiResponse.apiError = error.response.data;
                return apiResponse;
            })
    }


    public loadBusinessPurpose(resourceId: string) : Promise<ApiResponse<BusinessPurpose>>{
        const apiResponse = new ApiResponse<BusinessPurpose>()
        return this.instance.get(`/admin/metadata/business_purpose/${resourceId}`)
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            })
            .catch(error => {
                apiResponse.apiError = error.response.data;
                return apiResponse;
            })
    }

    public createBusnessPurpose(businessPurpose: BusinessPurposeCreate) : Promise<ApiResponse<BusinessPurpose>> {
        const apiResponse = new ApiResponse<BusinessPurpose>()
        const businessPurposeCreate = {
            resource_type: 'business_purpose',
            data: businessPurpose
        } 
        return this.instance.post('/admin/metadata/create', businessPurposeCreate)
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            }) 
            .catch(error => {
                apiResponse.apiError = error.response.data;
                return apiResponse;
            })
    }


    public updateBusinessPurpose(businessPurpose: BusinessPurposeUpdate) : Promise<ApiResponse<BusinessPurpose>> {
        const apiResponse = new ApiResponse<BusinessPurpose>()
        const businessPurposeUpdate = {
            resource_type: 'business_purpose',
            data: businessPurpose
        }
        return this.instance.put('/admin/metadata/update', businessPurposeUpdate)
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            }) 
            .catch(error => {
                apiResponse.apiError = error.response.data;
                return apiResponse;
            })
    }


    public deleteBusinessPurpose(resourceId: string) : Promise<ApiResponse<BusinessPurpose>> {
        const apiResponse = new ApiResponse<BusinessPurpose>()
        return this.instance.delete(`/admin/metadata/business_purpose/${resourceId}`)
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            }) 
            .catch(error => {
                apiResponse.apiError = error.response.data;
                return apiResponse;
        })
    }

}