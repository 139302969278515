import { ApiResponse } from "@/models/http/ApiResponse"
import { Organization, OrganizationModel } from "@/models/organization/organization.model"
import { HttpBaseService } from "./httpBase.service"
import { scheduleManagerApiKeycloak, keycloakRealm } from "@/app.config";


export class OrganizationService extends HttpBaseService{

    private static classInstance?: OrganizationService
    static apiBaseUrl = scheduleManagerApiKeycloak

    constructor(token:string) {
        super(token, OrganizationService.apiBaseUrl)
    }


    public static getInstance(token:string): OrganizationService{ 

        if(!this.classInstance) {
            this.classInstance = new OrganizationService(token)
        }

        return this.classInstance
    }

    public getOrganizationUnitByFromKeycloak(payload: string): Promise<ApiResponse<OrganizationModel>> {
        const apiResponse = new ApiResponse<OrganizationModel>()

            return this.instance.get('/admin/realms/shield/groups?briefRepresentation=true&q=tenant:'+payload)
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            }) 
            .catch(error => {
                apiResponse.apiError = error.response.data;
                return apiResponse;
            })
    } 

}
