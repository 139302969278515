








import { Vue } from "vue-property-decorator";
export default Vue.extend({
    name: 'FormTags',
    props: [
        'placeholder',
        'label',
        'tagsAdded',
        'disabled',
        'width'
    ],

    data() {
        return {
            tags: this.tagsAdded
        }
    },

    methods: {
        onTagsAdd (event: Event) {
            this.$emit('afterEnter', this.tags)
        }
    },
})
