import {Module} from "vuex";
import {RootState} from "@/store/types";
import {getters} from "@/store/modules/appointmentLocation/appointmentLocation-getters";
import {actions} from "@/store/modules/appointmentLocation/appointmentLocation-actions";
import {mutations} from "@/store/modules/appointmentLocation/appointmentLocation-mutations";
import { appointmentLocationState } from "./types";
export const state: appointmentLocationState = {
    appointmentLocation : undefined,
    appointmentLocations: [],
    appointmentLocationSelectBox: [],
    appointmentLocationsPublished: [],
    filterStatut: null,
    filterSearchText: null,
};

export const appointmentLocation: Module<appointmentLocationState, RootState> = {
    namespaced : true,
    state,
    getters,
    actions,
    mutations,
};
