







































import { Vue } from "vue-property-decorator";
import ProgrammingHeader from "@/components/programming-header/programming-header.vue";
import FullCalendar, { FullCalendarComponent } from "@fullcalendar/vue";
import ListPlugin from "@fullcalendar/list";
import { mapGetters, mapActions } from "vuex";
import moment from "moment/moment";
import ProgrammingDetail from "@/components/schedule-modal/programming-detail/programming-detail.vue";
import { AppointmentSummaryState } from "@/common/api.enums";
export default Vue.extend({
  name: "Program_2",
  components: {
    ProgrammingHeader,
    FullCalendar,
    ProgrammingDetail,
  },
  data() {
    return {
      calendarPlugins: [ListPlugin],
      loading: false,
      extendedPropsBusinessId: ''
    };
  },
  computed: {
    ...mapGetters("scheduleTemplate", ["getProgrammingAppointments", "getScheduleAppointments"]),
    ...mapGetters("admin", ["getProgrammingSpinner"]),
    ...mapGetters('layout',['getSidebarActiveElement', 'getActiveSidebarProgrammation']),
    calendar(): any {
      return (this.$refs.fullCalendar as FullCalendarComponent).getApi();
    },
    calendarOptions(): any {
      return {
        customButtons: {
          listDay: {
            text: 'Jour',
            click: () => {
              this.listDay()
            }
          },
          listWeek: {
            text: 'Semaine',
            click: () => {
              this.listWeek()
            }
          },
          listMonth: {
            text: 'Mois',
            click: () => {
              this.listMonth()
            }
          },
          prev: {
            click: () => {
              this.prev()
            }
          },
          next: {
            click: () => {
              this.next()
            }
          }
        },
        header: {
          left: "prev,next,today",
          center: "title",
          right: "listDay,listWeek,listMonth",
        },
        buttonText: {
          today: "Aujourd'hui",
        },
        events: this.getProgrammingAppointments,
        eventLimit: true,
        eventLimitText: "En plus",
        slotEventOverlap: false,
        defaultView: "listWeek",
        locale: "fr",
        selectable: true,
        selectHelper: true,
        editable: true,
        droppable: true,
        weekends: false,
        contentHeight: "auto",
        noEventsMessage: 'Pas d\'evenement sur cette periode',
        eventRender: (info: any) => {
          info.el.querySelector(".fc-list-item-title").innerHTML =
            "<a>" + info.event.title + " #" + info.event.extendedProps.business_id + "</a>";
          const appointment = info.el.querySelector(".fc-list-item-title").firstChild
          appointment.setAttribute("id", info.event.id);
          appointment.addEventListener("click", (event: Event) => {
            event.stopPropagation();
            this.eventOverView(info.event);
          });
          var moreIcon = document.createElement("span");
          moreIcon.className = "material-icons calendar-more-icon";
          moreIcon.innerHTML = 'more_vert';
          var groupBtn = document.createElement("div");
          groupBtn.className = "btn-group";
          var dropMenu = document.createElement("div");
          dropMenu.className = "dropdown-menu";
          var dropElement_1 = document.createElement("li");
          var dropElement_2 = document.createElement("li");
          var dropElement_3 = document.createElement("li");
          var dropElement_4 = document.createElement("li");
          dropElement_1.className = "d-flex justify-content-center px-1";
          dropElement_2.className = "d-flex justify-content-center px-1";
          dropElement_3.className = "d-flex justify-content-center px-1";
          dropElement_4.className = "d-flex justify-content-center px-1";
          var dropBtn = document.createElement("button");
          dropBtn.type = "button";
          dropBtn.setAttribute("data-toggle", "dropdown");
          dropBtn.setAttribute("aria-expanded", "false");
          dropBtn.className = "btn btn-light btn-circle btn-circle-sm d-flex align-items-center justify-content-center";
          dropBtn.appendChild(moreIcon);
          groupBtn.appendChild(dropBtn);
          groupBtn.appendChild(dropMenu);
          var actionCell = info.el.insertCell(3);
          var moreCell = info.el.insertCell(4);
          var bgc =
            info.el.getElementsByClassName("fc-list-item-title")[0].firstChild;
          actionCell.className = "fc-list-item-action fc-widget-content";
          moreCell.className = "fc-list-item-more fc-widget-content"
          var btnRemove = document.createElement("button");
          var btnAssign = document.createElement("button");
          var btnCancel = document.createElement("button");
          var btnPublish = document.createElement("button");
          var btnStart = document.createElement("button");
          btnCancel.type = "button";
          btnAssign.type = "button";
          btnRemove.type = "button";
          btnPublish.type = "button";
          btnStart.type = "button";
          groupBtn.addEventListener("shown.bs.dropdown", (event: Event) => {
            event.stopPropagation();
          });
          btnCancel.addEventListener("click", (event: Event) => {
            event.stopPropagation();
            this.cancelEvent(info.event);
          });
          btnPublish.addEventListener("click", (event: Event) => {
            event.stopPropagation();
            this.publishEvent(info.event);
          });
          btnRemove.addEventListener("click", (event: Event) => {
            event.stopPropagation();
            this.removeEvent(info.event);
          });
          btnAssign.addEventListener("click", (event: Event) => {
            event.stopPropagation();
            this.assignEvent(info.event);
          });
          btnStart.addEventListener("click", (event: Event) => {
            event.stopPropagation();
            this.startEvent(info.event);
          });
          btnCancel.className = "dropdown-item";
          btnAssign.className = "btn btn-info btn-sm btn-action mr-1";
          btnStart.className = "btn btn-success btn-sm btn-action mr-1";
          btnPublish.className = "btn btn-warning btn-sm btn-action mr-1";
          btnRemove.className = "dropdown-item";
          btnCancel.innerHTML = "Annuler";
          btnRemove.innerHTML = "Supprimer";
          btnAssign.innerHTML = "Affecter";
          btnPublish.innerHTML = "Republier";
          btnStart.innerHTML = "Demarrer";
          if (bgc) {
            switch (info.event.extendedProps.state) {
              case AppointmentSummaryState.published:
                {
                  bgc.classList.add("event-published");
                  actionCell.appendChild(btnAssign);
                  moreCell.appendChild(groupBtn);
                  dropMenu.appendChild(btnRemove);
                }
                break;
              case AppointmentSummaryState.cancelled:
                {
                  bgc.classList.add("event-canceled");
                }
                break;
              case AppointmentSummaryState.assigned:
                {
                  bgc.classList.add("event-affected");
                  actionCell.appendChild(btnStart);
                  moreCell.appendChild(groupBtn);
                  dropMenu.appendChild(btnCancel);
                }
                break;
              case AppointmentSummaryState.started:
                {
                  bgc.classList.add("event-started");
                }
                break;
              case AppointmentSummaryState.completed:
                {
                  bgc.classList.add("event-completed");
                }
                break;
              default: {
                bgc.classList.add("event-canceled");
                actionCell.appendChild(btnAssign);
                actionCell.appendChild(btnPublish);
                moreCell.appendChild(groupBtn);
                dropMenu.appendChild(btnRemove);
              }
            }
          }
        },
      };
    },
  },
  methods: {
    ...mapActions("scheduleTemplate", [
      "updateProgrammingAppointementState",
      "removeProgrammingAppointement",
      "setProgrammingAppointment",
    ]),
    ...mapActions("layout", ['setActiveSidebarProgrammation']),
    cancelEvent(arg: any) {
      this.updateProgrammingAppointementState({
        id: arg.id,
        title: arg.title,
        start: moment(arg.start).format("YYYY-MM-DD[T]HH:mm:ss") + ".0",
        end: moment(arg.end).format("YYYY-MM-DD[T]HH:mm:ss") + ".0",
        extendedProps: {
          participants: arg.extendedProps.participants,
          description: arg.extendedProps.description,
          state: AppointmentSummaryState.cancelled,
          appointment_id: arg.extendedProps.appointment_id,
          business_id: arg.extendedProps.business_id
        },
      })
    },
    assignEvent(arg: any) {
      this.eventOverView(arg)
    },
    makeToast(variant: string, message: string, title: string) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true,
        autoHideDelay: 5000
      })
    },  
    removeEvent(arg: any) {
      this.removeProgrammingAppointement({
        id: arg.id,
        title: arg.title,
        start: moment(arg.start).format("YYYY-MM-DD[T]HH:mm:ss") + ".0",
        end: moment(arg.end).format("YYYY-MM-DD[T]HH:mm:ss") + ".0",
        extendedProps: {
          participants: arg.extendedProps.participants,
          description: arg.extendedProps.description,
          state: AppointmentSummaryState.deleted,
          appointment_id: arg.extendedProps.appointment_id,
          business_id: arg.extendedProps.business_id
        },
      })
    },
    publishEvent(arg: any) {
      this.updateProgrammingAppointementState({
        id: arg.id,
        title: arg.title,
        start: moment(arg.start).format("YYYY-MM-DD[T]HH:mm:ss") + ".0",
        end: moment(arg.end).format("YYYY-MM-DD[T]HH:mm:ss") + ".0",
        extendedProps: {
          participants: arg.extendedProps.participants,
          description: arg.extendedProps.description,
          state: AppointmentSummaryState.published,
          appointment_id: arg.extendedProps.appointment_id,
          business_id: arg.extendedProps.business_id
        },
      })
    },
    startEvent(arg: any) {
      this.updateProgrammingAppointementState({
        id: arg.id,
        title: arg.title,
        start: moment(arg.start).format("YYYY-MM-DD[T]HH:mm:ss") + ".0",
        end: moment(arg.end).format("YYYY-MM-DD[T]HH:mm:ss") + ".0",
        extendedProps: {
          participants: arg.extendedProps.participants,
          description: arg.extendedProps.description,
          state: AppointmentSummaryState.started,
          appointment_id: arg.extendedProps.appointment_id,
          business_id: arg.extendedProps.business_id
        },
      })
    },
    eventOverView(arg: any) {
      this.setProgrammingAppointment(arg.id).then(() =>{
        this.$root.$emit("bv::show::modal", "programmingDetail", "#rowShow")
      }
      );
    },
    prev() {
      this.calendar.prev();
    },
    next() {
      this.calendar.next();
    },
    listDay() {
      this.changeView('listDay');
    },
    listWeek() {
      this.changeView('listWeek');
    },
    listMonth() {
      this.changeView('listMonth');
    },
    changeView(view : string) {
      this.calendar.changeView(view);
    },
  },
});
