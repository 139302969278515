
















import { Vue } from "vue-property-decorator";
import ProgrammingStat from './programming-stat/programming-stat.vue'
import moment from 'moment/moment';
import { mapGetters, mapActions } from 'vuex'
import { ProgramAppointmentSummary } from "@/models/appointment/appointmentModel";
import { AppointmentSummaryState } from "@/common/api.enums";
export default  Vue.extend({
    name: 'ProgrammingHeader',
    components: {
        ProgrammingStat
    },
    data() {
        return {
            programmingStart: '',
            programmingEnd: '',
        }
    },
    computed: {
        ...mapGetters("scheduleTemplate", ['getScheduleTemplate', 'getAppointmentWithSummary', 'getProgrammingAppointments']),
        totalAppointment(): number {
            let value = 0
            if(this.getProgrammingAppointments !== undefined){
                value = this.getProgrammingAppointments.filter((appointment: ProgramAppointmentSummary) =>
                 appointment.extendedProps.state !== AppointmentSummaryState.deleted).length
            }
            return value
        },
        assignedAppointment(): number {
            let value = 0
            if(this.getProgrammingAppointments !== undefined){
                value = this.getProgrammingAppointments.filter((appointment: ProgramAppointmentSummary) =>
                 appointment.extendedProps.state === AppointmentSummaryState.assigned).length
            }
            return value
        },
        publishedAppointment(): number {
            let value = 0
            if(this.getProgrammingAppointments !== undefined){
                value = this.getProgrammingAppointments.filter((appointment: ProgramAppointmentSummary) =>
                 appointment.extendedProps.state === AppointmentSummaryState.published).length
            }
            return value
        }
    },
    methods: {
        ...mapActions("scheduleTemplate", ['loadAppointmentWithSummary']),
        ...mapActions("admin", ['setProgrammingSpinner']),
        onDateStartSet(value: string){
            this.programmingStart = moment(value).format('YYYY-MM-DD')+'T00:00:00.0'
        },
        onDateEndSet(value: string){
            this.programmingEnd = moment(value).format('YYYY-MM-DD')+'T23:59:59.0'
            this.setProgrammingSpinner(true);
            this.loadAppointmentWithSummary({
                templateId: this.getScheduleTemplate.template_id,
                startDate: this.programmingStart,
                endDate: this.programmingEnd
            }).then(() => this.setProgrammingSpinner(false))
        },
    },
    beforeCreate() {
    }
})
