import { AppointmentScheduleTemplate } from "@/models/appointment/appointmentModel";
import { LocationModel } from "@/models/location/locationModel";
import { BusinessCategoryModel, BusinessPurpose } from "@/models/metadata/businessMetadata";
import { ParticipantModel } from "@/models/participant/participantModel";
import CommonVars from "./common-var";


export class Utils {
    static businessSelectBox(payload: Array<BusinessPurpose> | undefined): Array<{value: string, text: string}> {
        let buisnessSelectBox : {value: string, text: string}[] = [
            {value: '', text: 'sélectioner un thème'}
        ]
        buisnessSelectBox = [ {value: '', text: 'sélectioner un thème'} ]
        payload?.forEach(element => {
            buisnessSelectBox.push({
                value: element.purpose_id,
                text: element.name
            })
        });
        return buisnessSelectBox;
    }

    static locationSelectBox(payload: Array<LocationModel> | undefined): Array<{value: string, text: string}> {
        let locationSelectBox : {value: string, text: string}[] = [
            {value: '', text: 'sélectioner un lieu'}
        ]
        locationSelectBox = [ {value: '', text: 'sélectioner un lieu'} ]
        payload?.forEach(element => {
            locationSelectBox.push({
                value: element.location_id,
                text: element.name
            })
        });
        return locationSelectBox;
    }

    static categorySelectBox(payload: Array<BusinessCategoryModel> | undefined): Array<{value: string, text: string}> {
        let categorySelectBox : {value: string, text: string}[] = [
            {value: '', text: 'sélectioner une catégorie'}
        ]
        categorySelectBox = [ {value: '', text: 'sélectioner un catégorie'} ]
        payload?.forEach(element => {
            categorySelectBox.push({
                value: element.category_id,
                text: element.name
            })
        });
        return categorySelectBox;
    }

    static subCategorySelectBox(payload: Array<string> | undefined): Array<{value: string, text: string}> {
        let subCategorySelectBox : {value: string, text: string}[] = [
            {value: '', text: 'sélectioner une sous catégorie'}
        ]
        subCategorySelectBox = [ {value: '', text: 'sélectioner une sous-catégorie'} ]
        payload?.forEach(element => {
            subCategorySelectBox.push({
                value: element,
                text: element
            })
        });
        return subCategorySelectBox;
    }

    static scheduleTemplateSelectBox(payload: Array<AppointmentScheduleTemplate> | undefined): Array<{value: string, text: string}> {
        let scheduleTemplateSelectBox : {value: string, text: string}[] = [
            {value: '', text: 'sélectioner un template'}
        ]
        scheduleTemplateSelectBox = [ {value: '', text: 'sélectioner un template'} ]
        payload?.forEach(element => {
            scheduleTemplateSelectBox.push({
                value: element.template_id,
                text: element.metadata.title
            })
        });
        return scheduleTemplateSelectBox;
    }

    static participantSelectBox(payload: Array<ParticipantModel> | undefined): Array<{value: ParticipantModel | string, text: string | undefined}> {
        let participantSelectBox : {value: ParticipantModel | string, text: string | undefined}[] = [
            {value: '', text: 'add a Participant'}
        ]
        participantSelectBox = [ {value: '', text: 'ajouté un participant'} ]
        payload?.forEach(element => {
            if (element.full_name) {
                participantSelectBox.push({
                    value: element,
                    text: element.full_name
                })
            }else{
                participantSelectBox.push({
                    value: element,
                    text: element.ou
                })
            }   
        });
        return participantSelectBox;
    }

    public static _routesMap: Map<string, string> = new Map([
        [CommonVars.HOME, "/"],
        [CommonVars.PROFIL, "/profil"],
    ]);
    public static _pageMap: Map<string, string> = new Map([
        [CommonVars.HOME, "Espace de travail"],
        [CommonVars.PROFIL, "Mon profil"],
    ]);

    static clearForm(payload: Record<string, unknown>): void{
        for (const prop in payload) {
            if (Object.prototype.hasOwnProperty.call(payload, prop)) {
                payload[prop] = '';
            }
          }
    }

}