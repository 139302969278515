









import Vue from 'vue'
import { mapGetters } from 'vuex'
export default Vue.extend({
    name: 'ScheduleTableHeaderModified',
    props: [
        'data',
    ],
    computed: {
        ...mapGetters("scheduleTemplate",['getScheduleDays']),
        ...mapGetters("admin", ['getScheduleTemplateModification'])
    },
    methods: {
        onChange(){
            this.$emit('daySelectedOrNot', this.data.value, this.data.status)
        },
    }
})
