import { RessourceStateEnum } from '@/common/api.enums';
import { Utils } from '@/common/utils';
import { AppointmentLocationUpdateDto } from '@/dto/appointment/appointmentDto';
import { LocationModel } from '@/models/location/locationModel';
import { MutationTree } from 'vuex';
import { appointmentLocationState } from "./types";



export const mutations: MutationTree<appointmentLocationState> = {

    setAppointmentLocations(state, payload: Array<LocationModel>) {
        state.appointmentLocations = payload
        state.appointmentLocationsPublished = payload.filter((appointmentLocation) => appointmentLocation.state === RessourceStateEnum.Published)
        state.appointmentLocationSelectBox = Utils.locationSelectBox(state.appointmentLocationsPublished)
    },

    setAppointmentLocation(state, payload: LocationModel) {
        state.appointmentLocation = payload
    },

    setAppointmentLocationSelectBox(state, payload: []) {
        state.appointmentLocationSelectBox = payload
    },

    addAppointmentLocationSelectBox(state, payload: {value: string, text: string}) {
        state.appointmentLocationSelectBox.push(payload)
    },

    addAppointmentLocation(state, payload: LocationModel) {
        state.appointmentLocations?.push(payload)
        if (payload.state === RessourceStateEnum.Published) {
            state.appointmentLocationsPublished.push(payload)
        }
    },

    updatedAppointmentLocation(state, payload: AppointmentLocationUpdateDto) {
        const updatedLocation = state.appointmentLocations?.find(({ location_id }) => location_id === payload.location_id)
        if (updatedLocation !== undefined) {
            state.appointmentLocations?.splice(state.appointmentLocations?.indexOf(updatedLocation), 1, {
                ...updatedLocation,
                name: payload.name,
                display_name: payload.display_name,
                location_type: payload.location_type,
                state: payload.state,
                address: {
                    street_num: payload.address.street_num,
                    street: payload.address.street,
                    street2: payload.address.street2,
                    state: payload.address.state,
                    building: payload.address.building,
                    office: payload.address.office,
                    city: payload.address.city,
                    country: payload.address.country
                }
            })
            state.appointmentLocationsPublished = state.appointmentLocations?.filter((appointmentLocation) => appointmentLocation.state === RessourceStateEnum.Published)
            state.appointmentLocationSelectBox = Utils.locationSelectBox(state.appointmentLocationsPublished)
        }

    },

    deleteAppointmentLocation(state, payload: string) {
        state.appointmentLocations =  state.appointmentLocations?.filter(
                (appointmentLocation: LocationModel) => {
                    return appointmentLocation.location_id !== payload
                })

        state.appointmentLocationsPublished = state.appointmentLocations?.filter((appointmentLocation) => appointmentLocation.state === RessourceStateEnum.Published)
        state.appointmentLocationSelectBox = Utils.locationSelectBox(state.appointmentLocationsPublished)
    },

    setFilterStatut(state, payload: string | null){
        state.filterStatut = payload
    },

    setFilterSearchText(state, payload: string | null){
        state.filterSearchText = payload
    },

};
