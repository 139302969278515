






import { Vue } from "vue-property-decorator";
export default Vue.extend({
    name: 'FormSelect',
    props: [
        'formGroupId',
        'label',
        'formSelectId',
        'dataSelected',
        'dataOptions',
        'inputSize',
        'disabled',
        'bus',
        'width'
    ],
    data() {
        return {
            data: this.dataSelected,
            inputState: ''
        }
    },
    methods: {
        onChangeValue (event: Event) {
            this.$emit('afterEnter', this.data)
        },
        validateState(state: boolean): boolean{
            return state
        }
    },
    computed: {
        state(): boolean | null {
            if (this.inputState === 'true'){
                return true
            }else if(this.inputState === 'false'){
                return false
            }
            return null
        }
    },
    watch: {
        'bus':  function validateInput(state: string){
                    this.inputState = state
                },
        'inputData': function inputDataChange(state: string){
                        this.$emit('afterEnter', state)
                    }
    }
})
