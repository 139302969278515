import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { OrganizationState } from './types';

export const getters: GetterTree<OrganizationState, RootState> = {
    getOrganization(state): {value: string, text: string} | undefined {
        return state.organization;
    },
    getOrganizations(state): Array<{value: string, text: string}> {
        return state.organizations;
    },
};
