import { GetterTree } from "vuex";
import { RootState } from "@/store/types"
import { businessPurposeState } from "./types";
import { BusinessPurpose } from "@/models/metadata/businessMetadata";


export const getters: GetterTree<businessPurposeState, RootState> = {

    getBusinessPurpose(state): BusinessPurpose | undefined{
        return state.businessPurpose
    },

    getBusinessPurposes(state): Array<BusinessPurpose> | undefined{
        return state.businessPurposes
    },

    getBusinessPurposeSelectBox(state): Array<{value: string , text: string}>{
        return state.businessPurposeSelectBox
    },

    getBusinessPurposesPublished(state): Array<BusinessPurpose> | undefined{
        return state.businessPurposesPublished
    },

    getBusinessCategoryTable(state): Array<Record<string, unknown>> {
        return state.businessCategoryTable
    },

    getCategoryPurposes(state): Array<BusinessPurpose>{
        return state.categoryPurposes
    },

    getBusinessCategoriesPublished(state): Array<Record<string, unknown>> {
        return state.businessCategoriesPublished
    },

    getFilterStatut(state): string | null{
        return state.filterStatut
    },

    getFilterSearchText(state): string | null{
        return state.filterSearchText
    },

}