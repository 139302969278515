import { AppointmentUpdateDto } from '@/dto/appointment/appointmentDto';
import { AppointmentModel, AppointmentScheduleTemplate, AppointmentSummary, AppointmentWithSummary, ProgramAppointmentSummary } from '@/models/appointment/appointmentModel';
import { Duration } from '@/models/schedule/duration';
import { ScheduleAppointment, ScheduleSpan } from '@/models/schedule/scheduleModel';
import { MutationTree } from 'vuex';
import { scheduleTemplateState } from "./types";



export const mutations: MutationTree<scheduleTemplateState> = {

    setScheduleExcludeFreeday(state, payload: boolean) {
        state.scheduleExcludeFreeday = payload
    },

    setScheduleTemplate(state, payload: AppointmentScheduleTemplate) {
        const values = Object.values(payload.schedule_days)[0]
        state.scheduleTemplate = payload
        state.scheduleDuration = values[0].duration
        state.scheduleGraceTime = values[0].grace_time
        state.scheduleDays = new Map(Object.entries(payload.schedule_days))
    },

    setScheduleTemplates(state, payload: Array<AppointmentScheduleTemplate>) {
        state.scheduleTemplates = payload
    },

    addScheduleTemplates(state, payload: AppointmentScheduleTemplate) {
        state.scheduleTemplates?.push(payload)
    },

    setScheduleDays(state, payload: Map<string, ScheduleSpan[]> | undefined) {
        state.scheduleDays = payload
    },

    setScheduleDay(state, payload: Map<string, ScheduleSpan[]>) {
        let entry: any
        
        for (entry of payload.entries()) {
            state.scheduleDays?.set(entry[0], entry[1])
        }
    },

    setScheduleDuration(state, payload: Duration | undefined) {
        state.scheduleDuration = payload
        state.scheduleDays?.forEach((scheduleDay) => {
            scheduleDay.forEach((scheduleSpan) => {
                if(payload)
                scheduleSpan.duration = payload
            })
        })
    },

    setScheduleGraceTime(state, payload: Duration | undefined) {
        state.scheduleGraceTime = payload
        state.scheduleDays?.forEach((scheduleDay) => {
            scheduleDay.forEach((scheduleSpan) => {
                if(payload)
                scheduleSpan.grace_time = payload
            })
        })
    },

    setScheduleStartDate(state, payload: string) {
        state.scheduleStartDate = payload
    },

    setScheduleEndDate(state, payload: string) {
        state.scheduleEndDate = payload
    },

    setScheduleAppointmentOverride(state, payload: string) {
        state.scheduleAppointment = state.scheduleAppointments?.find(({id}) => id === payload)
    },

    setScheduleAppointment(state, payload: ScheduleAppointment) {
        state.scheduleAppointment = payload
    },

    clearScheduleAppointments(state){
        state.scheduleAppointments = []
    },

    setScheduleAppointments(state, payload: AppointmentModel[]) {
        state.scheduleAppointments = payload.map((appointment: AppointmentModel) => {
           return {
                id: appointment.appointment_id,
                title: appointment.title,
                start: appointment.start_time,
                end: appointment.end_time,
                extendedProps: {
                    participant: appointment.participants,
                    description: appointment.description
                },
            }
        })
        state.appointments_ = state.appointments?.map((appointment: AppointmentModel) => {
            let template_id = ''
            if (state.scheduleTemplate) {
                template_id = state.scheduleTemplate.template_id
            }
            return {
                title: appointment.title,
                group_id: appointment.appointment_id,
                description: appointment.description,
                business_reference: appointment.business_reference,
                start_time: appointment.start_time, 
                end_time: appointment.end_time,
                location_id: appointment.business_reference.location_id,
                participants: appointment.participants,
                access_control: {
                    write: {
                        groups: ["TEST", "TEST2"],
                        users: ["user1"]
                    },
                    read: {
                        groups: ["TEST"],
                        users: ["user1"]
                    },
                    assign: {
                        groups: ["TEST"],
                        users: ["user1"]
                    }
                },
                business_id_prefix: appointment.business_reference.business_id_prefix,
                template_id: template_id
            }
        })    
    },

    createScheduleAppointment(state, payload: ScheduleAppointment) {
        state.scheduleAppointments?.push(payload)
        if (state.appointments_ !== undefined){
            const appointment_ = state.appointments_[0]
            state.appointments_?.push({
                ...appointment_,
                group_id: undefined,
                start_time: payload.start,
                end_time: payload.end,
                participants: payload.extendedProps.participant
            })
        }
                    
    },

    updateScheduleAppointments(state, payload: ScheduleAppointment) {
        const scheduleAppointment = state.scheduleAppointments?.find(({id}) => id === payload.id)
        const appointment = state.appointments?.find(({ appointment_id }) => appointment_id === payload.id)
        const appointment_ = state.appointments_?.find(({ start_time }) => start_time === scheduleAppointment?.start)

        if (scheduleAppointment !== undefined && appointment !== undefined && appointment_ !== undefined){
            state.scheduleAppointments?.splice(state.scheduleAppointments?.indexOf(scheduleAppointment), 1, payload)
            state.appointments?.splice(state.appointments?.indexOf(appointment), 1, {
                ...appointment,
                start_time: payload.start,
                end_time: payload.end,
                participants: payload.extendedProps.participant
            })
            
            state.appointments_?.splice(state.appointments_?.indexOf(appointment_), 1, {
                ...appointment_,
                start_time: payload.start,
                end_time: payload.end,
                participants: payload.extendedProps.participant
            })
        }
    },

    removeScheduleAppointment(state, payload: ScheduleAppointment) {
        state.scheduleAppointments = state.scheduleAppointments?.filter((scheduleAppointment) => scheduleAppointment !== payload)
        const appointment = state.appointments?.find(({ appointment_id }) => appointment_id === payload.id)
        const appointment_ = state.appointments_?.find(({ start_time }) => start_time === payload.start)
        if (appointment !== undefined  && appointment_ !== undefined) {
            state.appointments = state.appointments?.filter((_appointment) => _appointment !== appointment)
            state.appointments_ = state.appointments_?.filter((appointment) => appointment !== appointment_)
        }
    },

    setAppointments(state, payload: Array<AppointmentModel>) {
        state.appointments = payload
    },

    setAppointmentWithSummary(state, payload: AppointmentWithSummary) {
        state.appointmentWithSummary = payload
        if (payload.appointments !== undefined) {
            state.programmingAppointments = payload.appointments.map((appointment: AppointmentSummary) => {
                return {
                    id: appointment.id,
                    title: appointment.title,
                    start: appointment.start_time,
                    end: appointment.end_time,
                    extendedProps: {
                        participants: appointment.participants,
                        description: appointment.description,
                        state: appointment.state,
                        appointment_id: appointment.appointment_id,
                        business_id: appointment.business_id,
                        business_reference: appointment.business_reference,
                        template_id: appointment.template_id,
                        group_id: appointment.group_id,
                        access_control: appointment.access_control
                    },
                }
            })   
        }
    },

    updateProgrammingAppointementState(state, payload: ProgramAppointmentSummary) {
        const appointment = state.programmingAppointments?.find(({id}) => id === payload.id)
        const summaryAppointment = state.appointmentWithSummary?.appointments?.find(({ id }) => id === payload.id)
        if (appointment !== undefined && summaryAppointment!== undefined){
            state.programmingAppointments?.splice(state.programmingAppointments?.indexOf(appointment), 1, payload)
            state.appointmentWithSummary?.appointments?.splice(state.appointmentWithSummary?.appointments?.indexOf(summaryAppointment), 1, {
                ...summaryAppointment,
                participants: payload.extendedProps.participants
            })
        }
    },

    updateProgrammingAppointement(state, payload: ProgramAppointmentSummary) {
        const appointment = state.programmingAppointments?.find(({ id }) => id === payload.id)
        const summaryAppointment = state.appointmentWithSummary?.appointments?.find(({ id }) => id === payload.id)
        if (appointment !== undefined && summaryAppointment!== undefined){
            state.programmingAppointments?.splice(state.programmingAppointments?.indexOf(appointment), 1, payload)
            state.appointmentWithSummary?.appointments?.splice(state.appointmentWithSummary?.appointments?.indexOf(summaryAppointment), 1, {
                ...summaryAppointment,
                state: payload.extendedProps.state
            })
        }
    },

    removeProgrammingAppointement(state, payload: ProgramAppointmentSummary) {
        const summaryAppointment = state.appointmentWithSummary?.appointments?.find(({ id }) => id === payload.id)
        state.programmingAppointments = state.programmingAppointments?.filter((appointment) => appointment.id !== payload.id)
        if (summaryAppointment !== undefined && state.appointmentWithSummary !== undefined) {
            state.appointmentWithSummary.appointments = state.appointmentWithSummary?.appointments?.filter((appointment) => appointment !== summaryAppointment)
        }
    },

    setProgrammingAppointment(state, payload: string) {
        state.programmingAppointment = state.programmingAppointments?.find(({ id }) => id === payload)
        const summaryAppointment = state.appointmentWithSummary?.appointments?.find(({ id }) => id === payload)
    },

    setProgrammingAppointmentStat(state, payload: { cancelled: number, assigned: number, published: number }) {
        state.programmingAppointmentStat = payload
    },

    setFilterStatut(state, payload: string | null){
        state.filterStatut = payload
    },

    setFilterSearchText(state, payload: string | null){
        state.filterSearchText = payload
    },

    setFilterPurposeSearch(state, payload: string | null){
        state.filterPurposeSearch = payload
    },
};