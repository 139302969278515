import { GetterTree } from "vuex";
import { RootState } from "@/store/types"
import { appointmentLocationState } from "./types";
import { LocationModel } from "@/models/location/locationModel";


export const getters: GetterTree<appointmentLocationState, RootState> = {

    getAppointmentLocation(state): LocationModel | undefined{
        return state.appointmentLocation
    },

    getAppointmentLocations(state): Array<LocationModel>{
        return state.appointmentLocations
    },

    getAppointmentLocationSelectBox(state): Array<{value: string, text: string}>{
        return state.appointmentLocationSelectBox
    },

    getAppointmentLocationsPublished(state): Array<LocationModel>{
        return state.appointmentLocationsPublished
    },

    getFilterStatut(state): string | null{
        return state.filterStatut
    },

    getFilterSearchText(state): string | null{
        return state.filterSearchText
    },

}