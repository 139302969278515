import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { getters } from '@/store/modules/organization/organization-getters';
import { actions } from '@/store/modules/organization/organization-actions';
import { mutations } from '@/store/modules/organization/organization-mutations';
import { OrganizationState } from './types';

export const state: OrganizationState = {
    organization: undefined,
    organizations: []
};

export const organization: Module<OrganizationState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
