import { ActionTree } from 'vuex';
import { scheduleTemplateState } from "./types";
import { RootState } from "@/store/types"
import { TemplateService } from '@/services/template.service';
import { ScheduleTemplateCreateDto, ScheduleTemplateUpdateDto } from '@/dto/schedule/scheduleDto';
import { ScheduleAppointment, ScheduleSpan } from '@/models/schedule/scheduleModel';
import { Duration } from '@/models/schedule/duration';
import { AppointmentScheduleTemplate, AppointmentWithSummaryPayload, ProgramAppointmentSummary } from '@/models/appointment/appointmentModel';
import { AppointmentCreateDto, AppointmentUpdateDto } from '@/dto/appointment/appointmentDto';
import { AppointmentSummaryPayload } from '@/common/acl';
import { AppointmentSummaryState } from '@/common/api.enums';
import { Vue } from "vue-property-decorator";
import { TYPE } from "vue-toastification";

export const actions: ActionTree<scheduleTemplateState, RootState> = {
    
    setScheduleExcludeFreeday(context, payload: boolean) {
        context.commit("setScheduleExcludeFreeday", payload)
    },

    setScheduleTemplate(context, payload: AppointmentScheduleTemplate) {
        context.commit("setScheduleTemplate", payload)
    },

    setScheduleDays(context, payload: Map<string, ScheduleSpan[]> | undefined) {
        context.commit("setScheduleDays", payload)
    },

    setScheduleDay(context, payload: Map<string, ScheduleSpan[]>) {
        context.commit("setScheduleDay", payload)
    },

    setScheduleDuration(context, payload: Duration | undefined) {
        context.commit("setScheduleDuration", payload)
    },

    setScheduleGraceTime(context, payload: Duration | undefined) {
        context.commit("setScheduleGraceTime", payload)
    },

    setScheduleStartDate(context, payload: string) {
        context.commit("setScheduleStartDate", payload)
    },

    setScheduleEndDate(context, payload: string) {
        context.commit("setScheduleEndDate", payload)
    },

    setScheduleAppointmentOverride(context, payload: string) {
        context.commit("setScheduleAppointmentOverride", payload)
    },

    setScheduleAppointment(context, payload: ScheduleAppointment) {
        context.commit("setScheduleAppointment", payload)
    },

    clearScheduleAppointments(context){
        context.commit('clearScheduleAppointments')
    },

    createScheduleAppointment(context, payload: ScheduleAppointment) {
        context.commit("createScheduleAppointment", payload)
    },

    updateScheduleAppointments(context, payload: ScheduleAppointment) {
        context.commit("updateScheduleAppointments", payload)
    },

    removeScheduleAppointment(context, payload: ScheduleAppointment) {
        context.commit("removeScheduleAppointment", payload)
    },

    createScheduleTemplate(context, payload: ScheduleTemplateCreateDto) {
        return TemplateService.getInstance(this.getters.getUser.access.access_token).createScheduleTemplate(payload)
            .then(response => {
                if (response.data) {
                    context.commit("addScheduleTemplates", response.data)
                    Vue.$toast("Template créé avec succès!");
                }
                if (response.apiError) {
                    Vue.$toast("Erreur lors de la creation de template!", { type: TYPE.ERROR });
                }
            })
    },

    createAppointmentScheduleTemplate(context, payload: ScheduleTemplateCreateDto) {
        return TemplateService.getInstance(this.getters.getUser.access.access_token).createAppointmentScheduleTemplate(payload)
            .then(response => {
                if (response.data) {
                    context.commit("setAppointments", response.data.appointments)
                    context.commit("setScheduleAppointments", response.data.appointments)
                    Vue.$toast("Rendez-vous créé avec succès!");
                    return true
                }
                if (response.apiError) {
                    Vue.$toast("Erreur lors de la création de rendez-vous!", { type: TYPE.ERROR });
                    return false
                }
            })
    },

    retrieveScheduleTemplate(context, payload: string) {       
        return TemplateService.getInstance(this.getters.getUser.access.access_token).loadScheduleTemplate(payload)
            .then(response => {
                if(response.data) {
                    context.commit("setScheduleTemplate", response.data)
                }
                if (response.apiError) {
                    Vue.$toast("Erreur lors du chargement du template!", { type: TYPE.ERROR });
                }
            }) 
    },

    retrieveScheduleTemplates(context) {
        return TemplateService.getInstance(this.getters.getUser.access.access_token).loadScheduleTemplates()
            .then(response => {
                if(response.data) {
                    context.commit("setScheduleTemplates", response.data)
                }
                if (response.apiError) {
                    Vue.$toast("Erreur lors du chargement des templates!", { type: TYPE.ERROR });
                }
            })
    },

    updateScheduleTemplate(context, payload: ScheduleTemplateUpdateDto) {
        return TemplateService.getInstance(this.getters.getUser.access.access_token).updateScheduleTemplate(payload)
            .then(response => {
                if (response.apiError) {
                    Vue.$toast("Erreur lors de la modification du template!", { type: TYPE.ERROR });
                } else {
                    TemplateService.getInstance(this.getters.getUser.access.access_token).loadScheduleTemplates()
                        .then(response => {
                            if(response.data) {
                                context.commit("setScheduleTemplates", response.data)
                            }
                            if (response.apiError) {
                                Vue.$toast("Erreur lors du chargement des templates!", { type: TYPE.ERROR });
                            }
                        })
                    Vue.$toast("Template modifié avec succès!");
                } 
            })
    },
    
    deleteScheduleTemplate(context, payload: string) {
        return TemplateService.getInstance(this.getters.getUser.access.access_token).deleteScheduleTemplate(payload)
            .then(() => {
                TemplateService.getInstance(this.getters.getUser.access.access_token).loadScheduleTemplates()
                    .then(response => {
                        if(response.data) {
                            context.commit("setScheduleTemplates", response.data)
                        }
                        if (response.apiError) {
                            Vue.$toast("Erreur lors du chargement des templates!", { type: TYPE.ERROR });
                        }
                    })
                Vue.$toast("Template supprimé avec succès!");
            })
    },

    publishScheduleAppointment(context, payload: AppointmentCreateDto[]) {
        return TemplateService.getInstance(this.getters.getUser.access.access_token).publishScheduleAppointments(payload)
            .then(response => {
                if(response.data) {
                    Vue.$toast("Rendez-vous publié avec succès!");
                    return true
                }
                if (response.apiError) {
                    Vue.$toast("Erreur lors de la publications des rendez-vous!", { type: TYPE.ERROR });
                    return false
                }
            })
    },

    loadAppointmentWithSummary(context, payload: AppointmentSummaryPayload) {
        return TemplateService.getInstance(this.getters.getUser.access.access_token).loadAppointmentWithSummary(payload)
            .then(response => {
                if (response.data) {
                    context.commit("setAppointmentWithSummary", response.data)
                    Vue.$toast("Rendez-vous chargés avec succès!");
                }
                if (response.apiError) {
                    Vue.$toast("Erreur lors du chargement des rendez-vous!", { type: TYPE.ERROR });
                }
            })
    },

    setProgrammingAppointment(context, payload: string) {
        context.commit("setProgrammingAppointment", payload)
    },

    updateProgrammingAppointementState(context, payload: ProgramAppointmentSummary) {
        const arg: AppointmentWithSummaryPayload = {
            action: '',
            data:{
                appointment_id: payload.extendedProps.appointment_id
            }
        }
        switch (payload.extendedProps.state){
            case AppointmentSummaryState.cancelled: {
                arg.action = 'cancel_appointment'
            } break
            case AppointmentSummaryState.started: {
                arg.action = 'start_appointment'
            } break
            case AppointmentSummaryState.published: {
                arg.action = 'publish_appointment'
            } break
            default: {
                arg.action = '',
                arg.data.appointment_id = ''
            }
        }

        return TemplateService.getInstance(this.getters.getUser.access.access_token).updateAppointmentWithSummaryState(arg)
            .then(() => {
                context.commit("updateProgrammingAppointementState", payload)
            })
            .catch(error => {
                console.log('error')
            })
    },

    updateProgrammingAppointement(context, payload: ProgramAppointmentSummary) {
        const requestPayload: AppointmentUpdateDto = {
            title: payload.title,
            group_id: payload.extendedProps.group_id,
            template_id: payload.extendedProps.template_id,
            description: payload.extendedProps.description,
            business_reference: {
                purpose_id: payload.extendedProps.business_reference.purpose_id,
                bp_name: payload.extendedProps.business_reference.bp_name,
                category_id: payload.extendedProps.business_reference.category_id,
                category_name: payload.extendedProps.business_reference.category_name,
                sub_category_name: payload.extendedProps.business_reference.sub_category_name
            },
            start_time: payload.start,
            end_time: payload.end,
            location_id: payload.extendedProps.business_reference.location_id,
            participants: payload.extendedProps.participants,
            access_control: payload.extendedProps.access_control,
            appointment_id: payload.extendedProps.appointment_id,
            business_id_prefix: 'EC'
        }
        return TemplateService.getInstance(this.getters.getUser.access.access_token).updateAppointmentWithSummary(requestPayload)
            .then(() => {
                context.commit("updateProgrammingAppointement", payload)
            })
            .catch(error => {
                console.log('error')
            })
    },

    removeProgrammingAppointement(context, payload: ProgramAppointmentSummary) {
        return TemplateService.getInstance(this.getters.getUser.access.access_token).removeAppointment(payload.extendedProps.appointment_id)
            .then(value => {
                context.commit("removeProgrammingAppointement", payload)
            })
            .catch(error => {
                console.log('error')
            })
    },

    setFilterStatut(context, payload: string | null){
        context.commit('setFilterStatut', payload)
    },

    setFilterSearchText(context, payload: string | null){
        context.commit('setFilterSearchText', payload)
    },

    setFilterPurposeSearch(context, payload: string | null){
        context.commit('setFilterPurposeSearch', payload)
    },
}
