





















































import { Vue } from "vue-property-decorator";
import { RessourceStateEnum } from '@/common/api.enums';
import { mapGetters, mapActions } from "vuex";
import FormSelectInline from '@/components/form-element/form-select/form-select-inline.vue'
export default Vue.extend({
    name: 'FilterPrime',
    props: [
        'topFilterTitle',
        'topFilterSelected',
        'topFilterOptions',
        'modalOpened',
        'pageRoute',
    ],
    components:{
        FormSelectInline
    },
    data() {
        return {
            purposeSelected: this.topFilterSelected,
            perPageOption: [5, 10, 20, 50, 100],
            perPageSelected: 10,
            stateOption: [
                { text: 'selectionner', value: null},
                { text: 'Publié', value: RessourceStateEnum.Published },
                { text: 'Brouillon', value: RessourceStateEnum.Draft }
            ],
            stateSelected: null,
            searchText: ''
        }
    },
    computed: {
        ...mapGetters("admin", ['getPerPage','getTableFilterOn', 'getSearchCategoryText']),
        ...mapGetters("businessPurpose", ['getBusinessPurposeSelectBox']),
    },
    methods: {
        ...mapActions('admin',['setSortBy', 'setSearchPurposeText', 'setSearchCategoryText',
                                'setSearchLocationText', 'setSearchTemplateText',
                                'setTableFilterOn']),
        ...mapActions('businessPurpose', ['filterByPurpose']),
        createClick(){
            this.$router.push(this.pageRoute);
        },
        onChange(val: string) {
            let selectedText = this.getBusinessPurposeSelectBox.find((purpose: any) => purpose.value === val)
            if(val === '') {
                selectedText.text = val
            }
            this.searchPurpose(['pattern_purpose'], selectedText)
        },
        onChangeValue (value: string) {
            this.$emit('afterEnter2', value)
        },
        onPerPageSelected(value: number){
            this.$emit('onPerPageSelected', value)
            this.perPageSelected = value
        },
        searchTextSet(value: string){
            this.$emit('searchTextSet', value)
        },
        onFilterStateSet(value: string){
            this.$emit('onFilterStateSet', value)
            this.stateSelected = value
        },
        onFilterSelectBox(value: string){
            this.$emit('onFilterSelectBox', value)
            this.purposeSelected = value
        }
    }
})
