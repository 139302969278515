import './set-public-path.js';
import Vue from 'vue';
import Vuex from 'vuex'
import singleSpaVue from 'single-spa-vue';

import App from './App.vue';
import router from './router';
import store from './store';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/common/style/particular_styles/app.scss'
//Import Vue-Toast
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { PluginOptions } from "vue-toastification/dist/types/src/types";

import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import shield from "@/plugins/shield/shield";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

const options: PluginOptions = {};
Vue.use(Toast, options);

Vue.use(Vuex)


Vue.config.productionTip = false;

const containerSelector = '#appPlace'

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h: any) {
      return h(App, {
        props: {
        },
      });
    },
    router,
    store,
    el: containerSelector
  },
});

export const bootstrap = vueLifecycles.bootstrap;

export function mount(props: any): any {
    Vue.use(shield, props.tokens)
    return vueLifecycles.mount(props);
} 
export const unmount = vueLifecycles.unmount;

export const devtools = {
  overlays: {
    selectors: [
      containerSelector
    ],
  }
};