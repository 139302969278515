

































import { Vue } from "vue-property-decorator";
import FilterPrime from "@/components/table-filter/filter.vue";
import TableView from '@/components/table-view/table-view.vue';
import { mapGetters, mapActions } from "vuex";
import moment from "moment/moment";
export default Vue.extend({
    name: "PatternList",
    components:{
        TableView,
        FilterPrime,
    },
    data(){
        return {
            selected: '',
            fields: [
                { 
                    key: "name", label: "Titre", sort: true, 
                    formatter: (value: null, key: string, item: any) => {
                        return item.metadata.title
                    },
                    filterByFormatted: true 
                },
                { 
                    key: "description", label: "Description", sort: true, 
                    formatter: (value: null, key: string, item: any) => {
                        return item.metadata.description
                    }
                },
                {   
                    key: "pattern_purpose", label: "Thème", sort: false,
                    formatter: (value: null, key: string, item: any) => {
                        return item.metadata.business_reference.bp_name
                    },
                    filterByFormatted: true
                },
                { 
                    key: "category", label: "Catégorie", sort: false,
                    formatter: (value: null, key: string, item: any) => {
                        return item.metadata.business_reference.category_name
                    }
                },
                { key: "state", label: "Statut", sort: true},
            ],
            transProps: {
                name: "flip-list",
            },
            totalRows: 2,
            currentPage: 1,
            filter: null,
            fixed: false,
            pageTitle: "Motifs de Rendez-Vous",
            updateTemplateRoute: "/gestion-rendez-vous/gestion-motifs/motif",
            newTemplateRoute: '/edition-rendez-vous/edition-motifs-de-rendez-vous'
        }
    },
    computed: {
        ...mapGetters("scheduleTemplate", ['getScheduleTemplates', 'getFilterPurposeSearch',
                         'getFilterStatut', 'getFilterSearchText']),
        ...mapGetters("businessPurpose", ['getBusinessPurposeSelectBox']),
        ...mapGetters("admin", ["getPerPage", "getSearchCategoryText",
                "getTableRow","getTableFilterOn", "getSearchTemplateText",
                "getTemplateFlashMessage"]),
        filteredItems() {
            const filtered = this.getScheduleTemplates.filter((item) => {
                return (
                    (!this.getFilterStatut || item.state === this.getFilterStatut) &&
                    (
                        !this.getFilterSearchText || this.getFilterSearchText.trim() === '' ||
                        item.metadata.title.toLowerCase().includes(this.getFilterSearchText) ||
                        item.metadata.description.toLowerCase().includes(this.getFilterSearchText)
                    ) &&
                    (!this.getFilterPurposeSearch || item.metadata.business_reference.purpose_id === this.getFilterPurposeSearch)
                );
            });
            return filtered;
        },
    },
    methods: {
        ...mapActions("admin",['setTableRow', 'setTemplateFlashMessage']),
        ...mapActions("scheduleTemplate", ['setFilterPurposeSearch',
                         'setFilterStatut', 'setFilterSearchText']),
        onRowSelected() {
            this.$router.push('/motif-rdv');
        },
        formatDate(value: string): string {
            return moment(value).locale('fr').format("DD/MM/YYYY")
        },
        makeToast(variant: string, message: string, title: string, delay: number) {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant,
                solid: true,
                autoHideDelay: delay
            })
        },
        filterStateSet(value: string){
            this.setFilterStatut(value)
        },
        filterSearchText(value: string){
            this.setFilterSearchText(value)
        },
        filterSelectBox(value: string){
            this.setFilterPurposeSearch(value)
        }
    },
    mounted() {
        // Set the initial number of items
        this.totalRows = this.getScheduleTemplates.length
    },
})
