import { AppointmentSummaryPayload } from "@/common/acl"
import { AppointmentCreateDto, AppointmentUpdateDto } from "@/dto/appointment/appointmentDto"
import { ScheduleTemplateCreateDto, ScheduleTemplateUpdateDto } from "@/dto/schedule/scheduleDto"
import { AppointmentScheduleTemplate, AppointmentsModel, AppointmentWithSummary, AppointmentWithSummaryPayload } from "@/models/appointment/appointmentModel"
import { ApiResponse } from "@/models/http/ApiResponse"
import { AxiosRequestConfig } from "axios"
import { HttpBaseService } from "./httpBase.service"
import { scheduleManagerApiUrl } from "@/app.config";


export class TemplateService extends HttpBaseService{

    private static classInstance?: TemplateService
    static apiBaseUrl = scheduleManagerApiUrl

    constructor(token:string) {
        super(token, TemplateService.apiBaseUrl) 
    }


    public static getInstance(token:string): TemplateService {

        if(!this.classInstance) {
            this.classInstance = new TemplateService(token)
        }

        return this.classInstance
    }


    public createScheduleTemplate(scheduleTemplate: ScheduleTemplateCreateDto): Promise<ApiResponse<AppointmentScheduleTemplate>> {
        const apiResponse = new ApiResponse<AppointmentScheduleTemplate>()
        return this.instance.post('/template/create', scheduleTemplate)
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            }) 
            .catch(error => {
                apiResponse.apiError = error.response.data;
                return apiResponse;
            })

    }

    public createAppointmentScheduleTemplate(appointmentScheduleTemplate: ScheduleTemplateCreateDto): Promise<ApiResponse<AppointmentsModel>> {
        const apiResponse = new ApiResponse<AppointmentsModel>()
        return this.instance.post('/schedule/appointment', appointmentScheduleTemplate)
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            }) 
            .catch(error => {
                apiResponse.apiError = error.response.data;
                return apiResponse;
            })

    }


    public loadScheduleTemplate(templateId: string): Promise<ApiResponse<AppointmentScheduleTemplate>>{
        const apiResponse = new ApiResponse<AppointmentScheduleTemplate>()
        return this.instance.get(`/template/${templateId}`)
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            }) 
            .catch(error => {
                apiResponse.apiError = error.response.data;
                return apiResponse;
            })
    }


    public loadScheduleTemplates() : Promise<ApiResponse<AppointmentScheduleTemplate[]>>{
        const apiResponse = new ApiResponse<AppointmentScheduleTemplate[]>()
        return this.instance.get('/templates')
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            }) 
            .catch(error => {
                apiResponse.apiError = error.response.data;
                return apiResponse;
            })
    }


    public updateScheduleTemplate(scheduleTemplate: ScheduleTemplateUpdateDto) : Promise<ApiResponse<AppointmentScheduleTemplate>> {
        const apiResponse = new ApiResponse<AppointmentScheduleTemplate>()
        return this.instance.put('/template/update', scheduleTemplate)
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            }) 
            .catch(error => {
                apiResponse.apiError = error.response.data;
                return apiResponse;
            })
    }
    

    public deleteScheduleTemplate(templateId: string) : Promise<ApiResponse<AppointmentScheduleTemplate>> {
        const apiResponse = new ApiResponse<AppointmentScheduleTemplate>()
        return this.instance.delete(`/template/${templateId}`)
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            }) 
            .catch(error => {
                apiResponse.apiError = error.response.data;
                return apiResponse;
            })
    }
    
    publishScheduleAppointments(publishedAppointments: AppointmentCreateDto[]) {
        const apiResponse = new ApiResponse<Record<string, unknown>>()
        const appointmentsCreate = {
            action: "publish_bulk",
            data: {
                appointments: publishedAppointments
            }
        }

        return this.instance.post('/appointment/workflow', appointmentsCreate)
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            }) 
            .catch(error => {
                apiResponse.apiError = error.response.data;
                return apiResponse;
            })
    }

    loadAppointmentWithSummary(payload: AppointmentSummaryPayload): Promise<ApiResponse<AppointmentWithSummary>> {
        const apiResponse = new ApiResponse<AppointmentWithSummary>()
        const requestParams: AxiosRequestConfig = {
            params: {
                start_date: payload.startDate,
                end_date: payload.endDate,
            }
        }
        return this.instance.get(`/appointment_with_summary/template/${payload.templateId}`, requestParams)
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            }) 
            .catch(error => {
                apiResponse.apiError = error.response.data;
                return apiResponse;
            })
    }

    updateAppointmentWithSummaryState(payload: AppointmentWithSummaryPayload) {
        const apiResponse = new ApiResponse<Record<string, unknown>>()
        return this.instance.post('/appointment/workflow', payload)
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            }) 
            .catch(error => {
                apiResponse.apiError = error.response.data;
                return apiResponse;
            })      
    }

    updateAppointmentWithSummary(payload: AppointmentUpdateDto) {
        const apiResponse = new ApiResponse<Record<string, unknown>>()
        return this.instance.put('/appointment/update', payload)
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            }) 
            .catch(error => {
                apiResponse.apiError = error.response.data;
                return apiResponse;
            })      
    }

    removeAppointment(appoinmtnet_id: string) {
        const apiResponse = new ApiResponse<Record<string, unknown>>()
        return this.instance.delete(`/appointment/${appoinmtnet_id}`)
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            }) 
            .catch(error => {
                apiResponse.apiError = error.response.data;
                return apiResponse;
            })
    }

}