import {Module} from "vuex";
import {RootState} from "@/store/types";
import {getters} from "@/store/modules/scheduleTemplate/scheduleTemplate-getters";
import {actions} from "@/store/modules/scheduleTemplate/scheduleTemplate-actions";
import {mutations} from "@/store/modules/scheduleTemplate/scheduleTemplate-mutations";
import { scheduleTemplateState } from "./types";
export const state: scheduleTemplateState = {
    scheduleTemplate : undefined,
    scheduleTemplates: [],
    scheduleDays: undefined,
    scheduleDuration: undefined,
    scheduleGraceTime: undefined,
    scheduleStartDate: '',
    scheduleEndDate: '',
    scheduleExcludeWeekEnd: false,
    scheduleExcludeFreeday: false,
    scheduleAppointment: undefined,
    scheduleAppointments: [],
    appointment_: undefined,
    appointments_: [],
    appointment: undefined,
    appointments: [],
    appointmentWithSummary: undefined,
    appointmentSummary: undefined,
    programmingAppointments: [],
    programmingAppointment: undefined,
    programmingAppointmentStat: {cancelled: 0, assigned: 0, published: 0},
    filterStatut: null,
    filterSearchText: null,
    filterPurposeSearch: null
};

export const scheduleTemplate: Module<scheduleTemplateState, RootState> = {
    namespaced : true,
    state,
    getters,
    actions,
    mutations,
};
