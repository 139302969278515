




























































import { Vue } from "vue-property-decorator";
import { mapGetters, mapActions } from 'vuex'
import { BusinessCategoryModel, BusinessPurpose } from "@/models/metadata/businessMetadata";
import { AppointmentScheduleTemplate } from "@/models/appointment/appointmentModel";
import { appMixin } from '@/mixins/app.mixin';
import moment from 'moment/moment';
import vSelect from "vue-select";
import { RessourceStateEnum } from "@/common/api.enums";
export default  Vue.extend({
    name: 'RightSidebarProgrammation',
    components: {
        vSelect
    },
    mixins: [appMixin],
    props:{
        /**
         * Show SideBar
         */
        showSide:{ type: Boolean, default: true,},
    },
    data() {
        return {
            service: '',
            serviceName: '',
            purpose: '',
            category: '',
            template: '',
            programmingStart: '',
            programmingEnd: '',
            showside: this.showSide,
            purposeOptions: new Array<{value: string, text: string}>(),
            categoryOptions: new Array<{value: string, text: string}>(),
            templateOptions: new Array<{value: string, text: string}>(),
            businessPurposeProjection: new Array<BusinessPurpose>(),
            scheduleTemplateOfCategory: new Array<AppointmentScheduleTemplate>()
        }
    },
    computed: {
        ...mapGetters("scheduleTemplate", ['getScheduleTemplate', 'getScheduleTemplates']),
        ...mapGetters("businessPurpose", ['getBusinessPurposeSelectBox', 'getBusinessPurpose', 'getBusinessPurposes']),
        ...mapGetters("businessCategory", ['getBusinessCategory']),
        ...mapGetters('layout', ['getSidebarActiveElement', 'getActiveSidebarProgrammation']),
        ...mapGetters("organization", ["getOrganizations"]),
        activeSidebar(): boolean{
            return this.getActiveSidebarProgrammation;
        },
    },
    methods: {
        ...mapActions("businessPurpose", ['retrieveBusinessPurpose', 'retrieveBusinessPurposes']),
        ...mapActions("businessCategory", ['retriveBusinessCategory']),
        ...mapActions("scheduleTemplate", ['retrieveScheduleTemplate', 'loadAppointmentWithSummary']),
        ...mapActions("admin", ['setProgrammingSpinner']),
        ...mapActions("layout", ['setActiveSidebarProgrammation']),
        toggleSidebar(): any {
            this.showside = !this.showside;
        },
        async filterPurpose(value: string): Promise<BusinessPurpose[]> {
            return this.getBusinessPurposes.filter((purpose: BusinessPurpose) => purpose.organization_unit == value)
        },
        async onServiceSet(value: string){
            this.filterPurpose(value).then(val => {
                this.service = value
                this.businessPurposeProjection = val
                this.purposeOptions = this.businessSelectBox(this.businessPurposeProjection.filter((businessPurpose) => businessPurpose.state === RessourceStateEnum.Published))
            })
        },
        async onPurposeSet(value: string) {
            this.purpose = value
            await this.retrieveBusinessPurpose(value).then( () => { 
                this.categoryOptions = this.categorySelectBox(this.getBusinessPurpose.categories.filter((businessCategory: BusinessCategoryModel) => businessCategory.state === RessourceStateEnum.Published))
            })
        },
        async onCategorySet(value: string){
            this.category = value
            this.scheduleTemplateOfCategory = []
            this.getScheduleTemplates.forEach((template: AppointmentScheduleTemplate) => {
                if(template.metadata.business_reference.category_id === value) {
                    this.scheduleTemplateOfCategory.push(template)
                }
            });
            this.templateOptions = this.scheduleTemplateSelectBox(this.scheduleTemplateOfCategory.filter((template) => template.state === RessourceStateEnum.Published))
        },
        async onTemplateSet(value: string) {
            this.template = value
            this.retrieveScheduleTemplate(value)
        },
        purposeSet(value: string){
            this.purpose = value
        },
        categorySet(value: string){
            this.category = value
        },
        templateSet(value: string){
            this.template = value
        },
        programmingSession() {
            const now = new Date()
            const afterOneMonth = new Date(now)
            afterOneMonth.setMonth(now.getMonth() + 1)
            this.programmingStart = this.formattingDate(now)
            this.programmingEnd = this.formattingDate(afterOneMonth)
        },
        formattingDate(value: Date): string{
            return moment(value).format('YYYY-MM-DD');
        },
        async initial() {
            await this.onServiceSet(this.getOrganizations[1].value)
            await this.onPurposeSet(this.purposeOptions[1].value)
            await this.onCategorySet(this.categoryOptions[1].value).then(() => {
                if(this.templateOptions.length > 1){
                    this.templateSet(this.templateOptions[1].value)
                }        
                if(this.template !== '') {
                    this.retrieveScheduleTemplate(this.template).then(
                        () => {
                            this.submitCalendar()
                        }
                    )
                }
            })
            
        },
        
        makeToast(variant: string, message: string, title: string) {
            this.$bvToast.toast(message, {
                title: title,
                variant: variant,
                solid: true,
                toaster: 'b-toaster-bottom-right',
                autoHideDelay: 3000
            })
        }, 
        onDateStartSet(value: string){
            this.programmingStart = value
        },
        onDateEndSet(value: string){
            this.programmingEnd = value
        },
        sidebarActive(state: boolean){         
            return !state
        },
        submitCalendar() {
            this.setProgrammingSpinner(true);
            this.loadAppointmentWithSummary({
                templateId: this.getScheduleTemplate.template_id,
                startDate: moment(this.programmingStart).format('YYYY-MM-DD')+'T23:59:59.0',
                endDate: moment(this.programmingEnd).format('YYYY-MM-DD')+'T23:59:59.0'
            }).then(() => {
                    this.setProgrammingSpinner(false)
                })
        }
    },
    created() {
        this.retrieveBusinessPurposes().then(
            async () => { 
                this.programmingSession()
                await this.initial()
            }
        )
        
    },
})
