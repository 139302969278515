
























































































































import { Vue } from "vue-property-decorator";
import { TYPE } from "vue-toastification";
import FormInputInline from "@/components/form-element/form-input/form-input-inline.vue";
import FormSelectInline from "@/components/form-element/form-select/form-select-inline.vue";
import ScheduleTemplate from "@/components/schedule-template/schedule-template.vue";
import moment from "moment/moment";
import { mapGetters, mapActions } from "vuex";
import { appMixin } from '@/mixins/app.mixin';
import { ScheduleSpan } from "@/models/schedule/scheduleModel";
import { RessourceStateEnum } from "@/common/api.enums";

export default Vue.extend({
  name: "PatternCreate",
  components: {
    FormInputInline,
    ScheduleTemplate,
    FormSelectInline,
  },
  mixins: [appMixin],
  props: [],
  data() {
    return {
      form: {
        label: "",
        description: "",
        purposeSelected: "",
        locationSelected: "",
        categorySelected: "",
        subCategorySelected: "",
        serviceSelected: "",
        codification: ""
      },
      validate: {
        label: "",
        description: "",
        purposeSelected: "",
        locationSelected: "",
        categorySelected: "",
        codification: ""
      },
      categoryOptions: new Array<{
        value: string | null | undefined;
        text: string | undefined;
      }>(),
      subCategoryOptions: new Array<{
        value: string | null | undefined;
        text: string | undefined;
      }>(),
      serviceOptions: [
        { value: "DEC_Unit", text: "DEC" },
        { value: "FBI_Unit", text: "FBI" },
        { value: "CIA_Unit", text: "CIA" },
        { value: "DEA_Unit", text: "DEA" },
      ],
      pageTitle: "Nouveau motif de rendez",
      data: {},
    };
  },
  computed: {
    ...mapGetters("scheduleTemplate", [
      "getScheduleDays",
      "getScheduleStartDate",
      "getScheduleEndDate",
    ]),
    ...mapGetters("appointmentLocation", [
      "getAppointmentLocationSelectBox",
      "getAppointmentLocations",
    ]),
    ...mapGetters("businessPurpose", [
      "getBusinessPurposeSelectBox",
      "getBusinessPurpose",
    ]),
    ...mapGetters("businessCategory", ["getBusinessCategory"]),
  },
  methods: {
    ...mapActions("scheduleTemplate", ["createScheduleTemplate"]),
    ...mapActions("appointmentLocation", [
      "retrieveAppointmentLocationForUser",
      "setAppointmentLocationSelectBox",
    ]),
    ...mapActions("businessPurpose", ["retrieveBusinessPurpose"]),
    ...mapActions("businessCategory", ["retriveBusinessCategory"]),
    ...mapActions("admin", ["setTemplateFlashMessage"]),

    onRegister(event: Event) {
      event.preventDefault();
      this.createScheduleTemplate({
          metadata: {
            title: this.form.label,
            description: this.form.description,
            business_reference: {
              purpose_id: this.form.purposeSelected,
              bp_name: this.getBusinessPurpose.name,
              category_id: this.form.categorySelected,
              category_name: this.getBusinessCategory.name,
              sub_category_name: this.form.subCategorySelected,
              location_id: this.form.locationSelected,
              business_id_prefix: this.form.codification,
            },
            participants: [
              {
                user_name: "zaffoh",
                full_name: "Zakari AFFOH",
                own_ship: true,
                meta_data: {
                  ref1: "Test Model",
                },
              },
            ],
          },
          schedule_start_time: moment().format("YYYY-MM-DD") + "T00:00:00.0",
          schedule_end_time:
            moment().add(7, "d").format("YYYY-MM-DD") + "T23:59:59.0",
          schedule_days: this.convertToJson(this.getScheduleDays),
          access_control: {
            write: {
              groups: ["TEST"],
            },
          },
          state: RessourceStateEnum.Draft
        }).then(() => {
            this.setTemplateFlashMessage({showMessage: true, message: 'Félicitation! Votre motif de rendez-vous a ete bien enregistrer.'})
            this.goToAnotherPage("/gestion-rendez-vous/gestion-motifs")
          }
        );
    },

    onPublished(event: Event) {
      event.preventDefault();
      if(this.validation() === true){
        this.createScheduleTemplate({
          metadata: {
            title: this.form.label,
            description: this.form.description,
            business_reference: {
              purpose_id: this.form.purposeSelected,
              bp_name: this.getBusinessPurpose.name,
              category_id: this.form.categorySelected,
              category_name: this.getBusinessCategory.name,
              sub_category_name: this.form.subCategorySelected,
              location_id: this.form.locationSelected,
              business_id_prefix: this.form.codification,
            },
            participants: [
              {
                user_name: "zaffoh",
                full_name: "Zakari AFFOH",
                own_ship: true,
                meta_data: {
                  ref1: "Test Model",
                },
              },
            ],
          },
          schedule_start_time: moment().format("YYYY-MM-DD") + "T00:00:00.0",
          schedule_end_time:
            moment().add(7, "d").format("YYYY-MM-DD") + "T23:59:59.0",
          schedule_days: this.convertToJson(this.getScheduleDays),
          access_control: {
            write: {
              groups: ["TEST"],
            },
          },
          state: RessourceStateEnum.Published
        }).then(() =>{
            this.setTemplateFlashMessage({showMessage: true, message: 'Félicitation! Votre motif de rendez-vous a ete bien publié.'})
            this.goToAnotherPage("/gestion-rendez-vous/gestion-motifs")
        }
        );
      }
    },

    onLabelSet(value: string) {
      this.form.label = value;
    },

    onDescriptionSet(value: string) {
      this.form.description = value;
    },

    onLocationSet(value: string) {
      this.form.locationSelected = value;
    },

    onCodificationSet(value: string) {
      this.form.codification = value
    },

    onServiceSet(value: string) {
      this.form.serviceSelected = value;
    },

    onPurposeSet(value: string) {
      this.form.purposeSelected = value;
      this.retrieveBusinessPurpose(value).then(
        () =>
          (this.categoryOptions = this.categorySelectBox(
            this.getBusinessPurpose.categories.filter((businessCategory) => businessCategory.state === RessourceStateEnum.Published)
          ))
      );
    },

    onCategorySet(value: string) {
      this.form.categorySelected = value;
      this.retriveBusinessCategory(value).then(
        () =>
          (this.subCategoryOptions = this.subCategorySelectBox(
            this.getBusinessCategory.sub_categories
          ))
      );
    },

    onSubCategorySet(value: string) {
      this.form.subCategorySelected = value;
    },

    convertToJson(
      payload: Map<string, ScheduleSpan[]>
    ): Record<string, unknown> {
      const result = Object.fromEntries(this.scheduleDaysValidation(payload));

      return (this.data = result);
    },

    goToAnotherPage(page: string) {
      this.$router.push(page);
    },

    validation(): boolean{
      let validationState = true
      if(this.form.label === '') {
          this.validate.label = 'false'
          validationState = false
      }else{
          this.validate.label = ''
      }
      if(this.form.purposeSelected === '') {
          this.validate.purposeSelected = 'false'
          validationState = false
      }else{
          this.validate.purposeSelected = ''
      }
      if(this.form.locationSelected === '') {
          this.validate.locationSelected = 'false'
          validationState = false
      }else{
          this.validate.locationSelected = ''
      }
      if((this.form.codification === '') || (this.form.codification.length > 4)) {
          this.validate.codification = 'false'
          Vue.$toast("Ce champ ne peut pas avoir plus de 4 lettres!", { type: TYPE.WARNING });
          validationState = false
      }else{
          this.validate.codification = ''
      }
      if(this.form.categorySelected === '') {
          this.validate.categorySelected = 'false'
          validationState = false
      }else{
          this.validate.categorySelected = ''
      }
      return validationState
    },

    scheduleDaysValidation(payload: Map<string, ScheduleSpan[]>): Map<string, ScheduleSpan[]> {
      for (let entry of payload.entries()) {
        entry[1] = entry[1].filter(elt => {
          if (elt.start_time === '' || elt.end_time === '') {
            return false
          }
          return true
        })
        if(entry[1].length === 1) {
          payload.set(entry[0], entry[1])
        }
        if(entry[1].length === 0) {
          payload.delete(entry[0])
        }
      }
      return payload
    }
  },
  mounted() {
    this.retrieveAppointmentLocationForUser()
  },
});
