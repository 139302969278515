

export enum LocationTypeEnum {
    Virtual = 'virtual',
    Physical = 'physical'
}

export enum TemplateTime {
    Morning_start = '06:00:00',
    Morning_end = '12:00:00',
    Afternoon_start = '13:00:00',
    Afternoon_end = '18:00:00'
}

export enum RessourceStateEnum {
    Draft = 'draft',
    Published = 'published',
    Deactivated = 'deactivated'
}

export enum AppointmentWorkflowEnum {
    Publish = 'publish',
    Publish_bulk = 'publish_bulk',
    Publish_group = 'publish_group',
    Postpone = 'postpone',
    Start_appointment = 'start_appointment',
    End_appointment = 'end_appointment',
    Cancel_appointment = 'cancel_appointment',
    Delete_appointment = 'delete_appointment'
}

export enum BusinessMetadataResourceEnum {

}

export enum DateScheduleHorizon {

}

export enum ScheduleStub {
    Start = 'start',
    End = 'end'
}

export enum TimeUnit {
    Years = 'years',
    Months = 'months',
    Weeks = 'weeks',
    Days = 'days',
    Hours = 'hours',
    Minutes = 'minutes'
}

export enum ScheduleDays {
    Lundi = 'monday',
    Mardi = 'tuesday',
    Mercredi = 'wednesday',
    Jeudi = 'thursday',
    Vendredi = 'friday',
    Samedi = 'saturday',
    Dimanche = 'sunday'
}

export enum AppointmentSummaryState {
    created = "created",
    published = "published",
    assigned = "assigned",
    started = "started",
    completed = "completed",
    cancelled = "cancelled",
    deleted = "deleted"
}

export enum TimeSlots {
    MorningBegin = '07:00:00',
    MorningEnd = '12:00:00',
    AfternoonBegin = '14:30:00',
    AfternoonEnd = '17:30:00',
}