import { ActionTree } from 'vuex';
import { OrganizationState } from '@/store/modules/organization/types';
import { RootState } from '@/store/types';
import { Vue } from "vue-property-decorator";
import { TYPE } from 'vue-toastification';
import { Organization, OrganizationModel } from '@/models/organization/organization.model';
import { OrganizationService } from '@/services/organization.service';

export const actions: ActionTree<OrganizationState, RootState> = {
    setOrganization(context, payload: {value: string, text: string}) {
        context.commit('setOrganization', payload);
    },
    setOrganizations(context, payload: Array<Organization>) {
        context.commit('setOrganizations', payload);
    }, 

    loadOrganizations(context) { 
        return OrganizationService.getInstance(this.getters.getUser.access.access_token).getOrganizationUnitByFromKeycloak(this.getters.getUser.tenant)
            .then(response => {
                if (response.data) {
                    const Organizations: OrganizationModel = response.data[0].subGroups.find(({ name }) => name === 'Organization')
                    context.commit('setOrganizationsByOrganizationModel', Organizations.subGroups)

                }
                if (response.apiError) {
                    Vue.$toast("Erreur lors du chargement des services!", { type: TYPE.ERROR });
                }
            })
    },
};
