
































require("@fullcalendar/core/main.min.css");
require("@fullcalendar/daygrid/main.min.css");
require("@fullcalendar/timegrid/main.min.css");


import { Vue } from "vue-property-decorator";
import FullCalendar, { FullCalendarComponent } from "@fullcalendar/vue";
import DayGridPlugin from "@fullcalendar/daygrid";
import TimeGridPlugin from "@fullcalendar/timegrid";
import InteractionPlugin from "@fullcalendar/interaction";
import ListPlugin from "@fullcalendar/list";
import ScheduleModal from "@/components/schedule-modal/schedule-modal.vue";
import { mapActions, mapGetters } from "vuex";
import { BPopover } from "bootstrap-vue";
import moment from "moment/moment";

export default Vue.extend({
  name: "AppointmentGeneration",
  components: {
    FullCalendar,
    ScheduleModal,
  },
  data() {
    return {
      calendarPlugins: [
        DayGridPlugin,
        TimeGridPlugin,
        InteractionPlugin,
        ListPlugin,
      ],
      event: {},
    };
  },

  computed: {
    ...mapGetters("scheduleTemplate", [
      "getScheduleAppointments",
      "getScheduleAppointment",
      "getAppointments_",
    ]),
    ...mapGetters('layout', ['getSidebarActiveElement', 'getActiveSidebarAppointementGeneration']),
    ...mapGetters("admin", ["getAppointmentGenerationSpinner"]),
    calendar(): any {
      return (this.$refs.fullCalendar as FullCalendarComponent).getApi();
    },

    calendarOptions(): any {
      return {
        header: {
          left: "prev,next,today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        buttonText: {
          today: "Aujourd'hui",
          month: "Mois",
          week: "Semaine",
          day: "Jour",
        },
        eventLimit: true,
        eventLimitText: "En plus",
        slotEventOverlap: false,
        defaultView: "timeGridWeek",
        minTime: "07:00:00",
        maxTime: "18:00:00",
        locale: "fr",
        events: this.getScheduleAppointments,
        selectable: true,
        selectHelper: true,
        editable: true,
        droppable: true,
        weekends: false,
        contentHeight: 650,
        eventRender: (info: any) => {
          new BPopover({
            propsData: {
              title: info.event.title,
              content: info.event.extendedProps.description,
              placement: "top",
              boundary: "scrollParent",
              boundaryPadding: 5,
              delay: 500,
              offset: 0,
              triggers: "hover",
              html: true,
              target: info.el,
            },
          }).$mount();
        },
      };
    },
  },

  methods: {
    ...mapActions("scheduleTemplate", [
      "setScheduleAppointmentOverride",
      "setScheduleAppointment",
      "publishScheduleAppointment",
    ]),
    ...mapActions("layout", ['setActiveSidebarAppointementGeneration']),

    handleClick(arg: any) {
      this.setScheduleAppointmentOverride(arg.event.id).then(() =>
        this.$root.$emit(
          "bv::show::modal",
          "AppointmentEventUpdateModal",
          "#rowShow"
        )
      );
    },
    handleSelect(arg: any) {
      this.setScheduleAppointment({
        id: "",
        title: this.getScheduleAppointments[0].title,
        start:
          moment(arg.start).format("YYYY-MM-DD") +
          "T" +
          moment(arg.start).format("hh:mm:ss"),
        end:
          moment(arg.end).format("YYYY-MM-DD") +
          "T" +
          moment(arg.end).format("hh:mm:ss"),
        extendedProps: this.getScheduleAppointments[0].extendedProps,
      });
      this.$root.$emit(
        "bv::show::modal",
        "AppointmentEventCreateModal",
        "#rowShow"
      );
    },

    onPublished(event: Event) {
      event.preventDefault();

      this.publishScheduleAppointment(this.getAppointments_);
    },

    prev() {
      this.calendar.prev();
    },

    next() {
      this.calendar.next();
    },
  },
});
