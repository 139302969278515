
export const scheduleManagerApiUrl = process.env.VUE_APP_API_SCHEDULE_MANAGER_URL
export const scheduleManagerApiKeycloak = process.env.VUE_APP_SCHEDULE_MANAGER_KEYCLOAK_URL
export const scheduleManagerApiReferential = process.env.VUE_APP_SCHEDULE_MANAGER_REFERENTIAL_URL
export const orchestratorUrl = process.env.VUE_APP_ORCHESTRATOR_URL 
export const keycloakRealm = process.env.VUE_APP_SCHEDULE_MANAGER_KEYCLOAK_REALM

export default {
    scheduleManagerApiUrl,
    scheduleManagerApiKeycloak,
    scheduleManagerApiReferential,
    orchestratorUrl,
    keycloakRealm
}