





























import Vue from 'vue'
import FormInput from '@/components/form-element/form-input/form-input.vue';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapActions, mapGetters } from 'vuex'
import { RessourceStateEnum } from '@/common/api.enums';
import { Utils } from '@/common/utils';
export default Vue.extend({
    name: 'PurposeCreate',
    components:{
        FormInput,
        vSelect
    },
    props: [],
    data() {
        return {
            form: {
                purpose: '',
                description: '',
                organizationSelected: '',
                organizationUnit: ''
            },
            validate: {
                purpose: '',
                organizationSelected: '',
            },
            pageTitle: 'Nouveau motif de rendez',
        }
    },
    computed: {
        ...mapGetters("businessPurpose", ["getBusinessPurposes"]),
        ...mapGetters("organization", ["getOrganizations"]),
        ...mapGetters("admin", ["getTableRow"]),
    },
    methods: {
        ...mapActions("businessPurpose", ['createBusinessPurpose']),
        ...mapActions("admin", ["setTableRow"]),
        validation(): boolean{
            let validationState = true
            if(this.form.purpose === '') {
                this.validate.purpose = 'false'
                validationState = false
            }else{
                this.validate.purpose = ''
            }
            if(this.form.organizationSelected === '') {
                this.validate.organizationSelected = 'false'
                validationState = false
            }else{
                this.validate.organizationSelected = ''
            }
            return validationState
        },
        onRegister(event: Event) {
            event.preventDefault()
            if(this.form.purpose.trim() !== '') {
                this.createBusinessPurpose({
                    name: this.form.purpose,
                    description: this.form.description,
                    organization_name: this.form.organizationSelected,
                    organization_unit: this.form.organizationUnit,
                    state: RessourceStateEnum.Draft,
                    access_control: {
                        write: {
                            groups: ["TEST"]
                        }
                    }
                }).then(() => {
                    this.$root.$emit('bv::hide::modal', 'purposeCreateModal', '#btnSubmit')
                })
            }
        },
        onPublished(event: Event) {
            event.preventDefault()
            if(this.validation() === true){
                this.createBusinessPurpose({
                    name: this.form.purpose,
                    description: this.form.description,
                    organization_name: this.form.organizationSelected,
                    organization_unit: this.form.organizationUnit,
                    state: RessourceStateEnum.Published,
                    access_control: {
                        write: {
                            groups: ["TEST"]
                        }
                    }
                }).then(() => {
                    this.$root.$emit('bv::hide::modal', 'purposeCreateModal', '#btnPublish')
                })
            }
        },
        onPurposeOrganizationSet(val: {text: string, value: string}) {
            this.form.organizationSelected = val.text
            this.form.organizationUnit = val.value
        },
        onPurposeSet(value: string) {
            this.form.purpose = value
        },
        onDescriptionSet(value: string) {
            this.form.description = value
        },
        hideModal(){
            this.validate.purpose = ''
            Utils.clearForm(this.form)
        }
    },
})
