













import Vue from 'vue'
import PurposeCreate from '../schedule-modal/purpose/purpose-create.vue'
import PurposeManage from '../schedule-modal/purpose/purpose-manage.vue';
import CategoryCreate from '../schedule-modal/category/category-create.vue';
import CategoryManage from '../schedule-modal/category/category-manage.vue';
import LocationCreate from '../schedule-modal/location/location-create.vue';
import LocationManage from '../schedule-modal/location/location-manage.vue';
import AppointmentEventUpdate from '../schedule-modal/appointment-event/appointment-event-update.vue';
import AppointmentEventCreate from '../schedule-modal/appointment-event/appointment-event-create.vue';

export default Vue.extend({
    name: 'ScheduleModal',
    components:{
        PurposeCreate,
        PurposeManage,
        CategoryCreate,
        CategoryManage,
        LocationCreate,
        LocationManage,
        AppointmentEventUpdate,
        AppointmentEventCreate
    },
    props: [
        'purposeManage',
        'purposeCreate',
        'categoryCreate',
        'categoryManage',
        'locationCreate',
        'locationManage',
        'recurrence',
        'appointmentEventUpdate',
        'appointmentEventCreate',
    ],
    data() {
      return {
        
      }
    },
    methods: {
      
    },
})
