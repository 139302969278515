











import Vue from 'vue';
import AppointementTimeModified from './appointment-time-setter/appointment-time-modified.vue';
export default Vue.extend({
    name: 'ScheduleTemplateHeaderModified',
    components: {
        AppointementTimeModified,
    },
})
