import { ActionTree } from 'vuex';
import { appointmentLocationState } from "./types";
import { RootState } from "@/store/types"
import { AppointmentLocationCreate, AppointmentLocationUpdateDto } from '@/dto/appointment/appointmentDto';
import { LocationService } from '@/services/location.service';
import { LocationModel } from '@/models/location/locationModel';
import { Utils } from '@/common/utils';
import { RessourceStateEnum } from '@/common/api.enums';
import { Vue } from "vue-property-decorator";
import { TYPE } from "vue-toastification";

export  const actions: ActionTree<appointmentLocationState, RootState> = {

    setAppointmentLocation(context, payload: LocationModel) {
        context.commit("setAppointmentLocation", payload)
    },

    setAppointmentLocations(context, payload: Array<LocationModel>) {
        context.commit("setAppointmentLocations", payload)
    },

    setAppointmentLocationSelectBox(context, payload: Array<LocationModel>) {
        context.commit("setAppointmentLocationSelectBox", Utils.locationSelectBox(payload))
    },

    createAppointmentLocation(context, payload: AppointmentLocationCreate) {
        
        return LocationService.getInstance(this.getters.getUser.access.access_token).createAppointmentLocation(payload)
            .then(response => {
                if (response.data) {
                    context.commit("addAppointmentLocation", response.data)
                    if(response.data.state === RessourceStateEnum.Published)
                        context.commit("addAppointmentLocationSelectBox", { value: response.data.location_id, text: response.data.name })
                    Vue.$toast("Lieu créé avec succès!");
                }
                if (response.apiError) {
                    Vue.$toast("Erreur lors de la creation du lieu!", { type: TYPE.ERROR });
                }
            })
    },

    updateAppointmentLocation(context, payload: AppointmentLocationUpdateDto) {
        return LocationService.getInstance(this.getters.getUser.access.access_token).updateAppointmentLocation(payload)
        .then((response) => {
                //I do this because response.data === ""
                if (response.apiError) {
                    Vue.$toast("Erreur lors de la modification du lieu!", { type: TYPE.ERROR });
                } else {
                    Vue.$toast("Lieu modifié avec succès!");
                    context.commit('updatedAppointmentLocation', payload)
                }
            })
    },

    retrieveAppointmentLocation(context, payload: string) {
        
        return LocationService.getInstance(this.getters.getUser.access.access_token).loadAppointmentLocation(payload)
            .then(response => {
                if (response.data) {
                    context.commit("setAppointmentLocation", response.data)
                }
                if (response.apiError) {
                    Vue.$toast("Erreur lors du chargement du lieu!", { type: TYPE.ERROR });
                }
            })
    },

    retrieveAppointmentLocationForUser(context) {
        
        return LocationService.getInstance(this.getters.getUser.access.access_token).loadAppointmentLocationForUser()
            .then(response => {
                if (response.data) {
                    context.commit("setAppointmentLocations", response.data)
                }
                if (response.apiError) {
                    Vue.$toast("Erreur lors du chargement des lieux!", { type: TYPE.ERROR });
                }
            })
    },

    deleteAppointmentLocation(context, payload: string) {
        return LocationService.getInstance(this.getters.getUser.access.access_token).deleteAppointmentLocation(payload)
            .then(response => {
                if (response.apiError) {
                    Vue.$toast("Erreur: Désolé, ce lieu ne peut être supprimée car il dispose de rendez-vous!", { type: TYPE.ERROR });
                } else {
                    context.commit("deleteAppointmentLocation", payload)
                    Vue.$toast("Lieu supprimé avec succès!");
                }
            })
    },

    setFilterStatut(context, payload: string | null){
        context.commit('setFilterStatut', payload)
    },

    setFilterSearchText(context, payload: string | null){
        context.commit('setFilterSearchText', payload)
    },


}