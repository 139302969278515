import { MutationTree } from 'vuex';
import { OrganizationState } from '@/store/modules/organization/types';
import { Organization, OrganizationModel } from '@/models/organization/organization.model';


export const mutations: MutationTree<OrganizationState> = {
    setOrganization(state, payload: {value: string, text: string}) {
        state.organization = payload;
    },
    setOrganizations(state, payload: Array<Organization>) {
        state.organizations = payload.map((organization: Organization) => {
            return {
                value: organization.description,
                text: organization.description
            }
        });
    },
    setOrganizationsByOrganizationModel(state, payload: Array<OrganizationModel>) {
        state.organizations = payload.map((organization: OrganizationModel) => {
            return {
                value: organization.id,
                text: organization.name
            }
        });
    },

};
