






























import Vue from 'vue'
import ScheduleTemplateHeader from '@/components/schedule-template/schedule-template-header/schedule-template-header.vue';
import ScheduleTemplateSetter from '@/components/schedule-template/schedule-template-setter/schedule-template-setter.vue';
import ScheduleTemplateSetterModified from './modification/schedule-template-setter/schedule-template-setter-modified.vue';
import ScheduleTemplateHeaderModified from './modification/schedule-template-header/schedule-template-header-modified.vue';
export default Vue.extend({
    name: 'ScheduleTemplate',
    props: [
        'modified',
        'disabled'
    ],
    components:{
        ScheduleTemplateHeader,
        ScheduleTemplateSetter,
        ScheduleTemplateSetterModified,
        ScheduleTemplateHeaderModified
    }
})
