import { BusinessCategoryModel, BusinessPurpose } from '@/models/metadata/businessMetadata';
import { BusinessCategoryUpdate } from '@/dto/metadata/metadataDto';
import { MutationTree } from 'vuex';
import { businessCategoryState } from "./types";
import { RessourceStateEnum } from '@/common/api.enums';



export const mutations: MutationTree<businessCategoryState> = {

    setBusinessCategory(state, payload: BusinessCategoryModel) {
        state.businessCategory = payload
    },

    setBusinessCategoryOverride(state, payload: string) {
        state.businessCategory = state.businessCategories?.find(({category_id}) => category_id === payload)
    },

    setBusinessCategoriesPublished(state){
        state.businessCategoriesPublished = state.businessCategoryTable.filter((businessPurpose) => businessPurpose.state === RessourceStateEnum.Published)
    },

    setBusinessCategories(state, payload: BusinessCategoryModel[]) {
        state.businessCategories = payload
    },

    addBusinessCategory(state, payload: BusinessCategoryModel) {
        state.businessCategories?.push(payload)
    },

    setCategoryPurposes(state, payload: Array<BusinessPurpose>) {
        state.categoryPurposes = payload
    },

    setBusinessCategoryTable(state, payload: BusinessPurpose[]) {
        state.businessCategoryTable = [];
        payload?.forEach((purpose: BusinessPurpose) => {
            if (purpose.categories?.length !== 0 &&
                    purpose.categories !== undefined) {
            purpose.categories.forEach((category: BusinessCategoryModel) => {
                    state.businessCategoryTable?.push(category);
                });
            }
        });
        this.setBusinessCategoriesPublished()
    },

    filterByPurpose(state, payload: string) {
        const categoryPurposes = state.categoryPurposes.filter((purpose) => purpose.purpose_id === payload)
        state.businessCategoryTable = [];
        categoryPurposes?.forEach((purpose: BusinessPurpose) => {
            if (purpose.categories?.length !== 0 &&
                    purpose.categories !== undefined) {
            purpose.categories.forEach((category: BusinessCategoryModel) => {
                    state.businessCategoryTable?.push(category);
                });
            }
        });
    },

    setFilterStatut(state, payload: string | null){
        state.filterStatut = payload
    },

    setFilterSearchText(state, payload: string | null){
        state.filterSearchText = payload
    },

    setFilterPurposeSearch(state, payload: string | null){
        state.filterPurposeSearch = payload
    },

};