




























import { Vue } from "vue-property-decorator";
import TableView from '@/components/table-view/table-view.vue';
import { mapGetters, mapActions } from "vuex";
import { BusinessCategoryModel } from "@/models/metadata/businessMetadata";
import FilterPrime from "@/components/table-filter/filter.vue";
export default Vue.extend({
    name: "PurposesTab",
    components:{
        TableView,
        FilterPrime,
    },
    
    data(){
        return {
            selected: null,
            fields: [
                { key: "name", label: "Thème", sortable: true},
                { key: "description", label: "Description", sortable: false},
                { 
                    key: "categories", label: "Catégories", sortable: false,
                    formatter: (value: []) => {
                        var categories= new Array<string>()
                        value.forEach((category: BusinessCategoryModel) => {
                            categories.push(category.name)
                        });
                        return categories.toString()
                    }
                },
                { key: "organization_name", label: "Service de gestion", sortable: true},
                { key: "state", label: "Statut", sortable: true}
            ],
            currentPage: 1,
            perPage: 2,
            filter: null,
            fixed: false,
            theAnotherPage: "/creation-rendez-vous/gestion-theme",
        }
    },
    computed: {
        ...mapGetters("admin",['getPerPage', 'getPageOptions', 'getTableFilterOn',
                        'getCurrentPage', 'getSearchPurposeText', 'getTableRow']),
        ...mapGetters("businessPurpose", ["getBusinessPurposes", "getFilterStatut", "getFilterSearchText"]),
        rows(): number {
            return this.getTableRow.purpose
        },
        filteredItems() {
            const filtered = this.getBusinessPurposes.filter((item) => {
                return (
                    (!this.getFilterStatut || item.state === this.getFilterStatut) &&
                    (
                        !this.getFilterSearchText || this.getFilterSearchText.trim() === '' ||
                        item.name.toLowerCase().includes(this.getFilterSearchText) ||
                        item.description.toLowerCase().includes(this.getFilterSearchText)
                    )
                );
            });
            return filtered;
        },
    },
    methods: {
        ...mapActions("businessPurpose", ["retrieveBusinessPurposes", "setFilterStatut", "setFilterSearchText"]),
        ...mapActions("admin",['setTableRow']),
        filterStateSet(value: string){
            this.setFilterStatut(value)
        },
        filterSearchText(value: string){
            this.setFilterSearchText(value)
        }
    },
    mounted() {
        // Set the initial number of items
        this.retrieveBusinessPurposes().then(() => {
            this.setTableRow({ ...this.getTableRow, purpose: this.getBusinessPurposes.length })
        })
        
    },
})
