


















import { Vue } from "vue-property-decorator";
export default  Vue.extend({
    name: 'ProgrammingStat',
    
    props: [
        'icon',
        'value',
        'title'
    ],
    computed: {
        
    },
    methods: {
        
    },
})
