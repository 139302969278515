















































import Vue from 'vue'
import FormInput from "@/components/form-element/form-input/form-input.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapGetters, mapActions } from "vuex";
import { RessourceStateEnum } from '@/common/api.enums';
import { Utils } from '@/common/utils';
export default Vue.extend({
    name: 'PurposeManage',
    components: {
        FormInput,
        vSelect,
    },
    props: [],
    data() {
      return {
        form: {
            purpose: '',
            description: '',
            organizationSelected: '',
        },
        validate: {
            purpose: '',
            organizationSelected: ''
        },
        disabled: true,
        deleteBtn: true,
        pageTitle: 'Nouveau motif de rendez'
      }
    },
    computed: {
        ...mapGetters('businessPurpose', ['getBusinessPurpose', 'getBusinessPurposes']),
        ...mapGetters("organization", ["getOrganizations"]),
        ...mapGetters("admin", ["getTableRow"]),
    },
    methods: {
        ...mapActions("businessPurpose", ['updateBusinessPurpose', 'deleteBusinessPurpose', 'retrieveBusinessPurposes']),
        ...mapActions("admin", ["setTableRow"]),
        modalInitialize(){
            this.onPurposeNameSet(this.getBusinessPurpose.name);
            this.onPurposeDescriptionSet(this.getBusinessPurpose.description)
            this.organization();
        },
        organization() {
            this.onPurposeOrganizationSet(
                {
                    text: this.getBusinessPurpose.organization_name, 
                    value: this.getBusinessPurpose.organization_unit
                }
            )
        },
        onPurposeNameSet(value: string) {
            this.form.purpose = value
        },
        onPurposeDescriptionSet(value: string) {
            this.form.description = value
        },
        onPurposeOrganizationSet(val: {text: string, value: string}) {
            this.form.organizationSelected = val.text
            this.form.organizationUnit = val.value
        },
        showModal() {
            this.modalInitialize()
            if(this.getBusinessPurpose.state === RessourceStateEnum.Draft){
                this.disabled = false
                this.deleteBtn = false
            }else{
                this.disabled = true
                this.deleteBtn = true
            }
        },
        validation(): boolean{
            let validationState = true
            if(this.form.purpose === '') {
                this.validate.purpose = 'false'
                validationState = false
            }else{
                this.validate.purpose = ''
            }
            if(this.form.organizationSelected === '') {
                this.validate.organizationSelected = 'false'
                validationState = false
            }else{
                this.validate.organizationSelected = ''
            }
            return validationState
        },
        onRegister(event: Event) {
            event.preventDefault()
            if(this.form.purpose.trim() !== '') {
                this.updateBusinessPurpose({
                    purpose_id: this.getBusinessPurpose.purpose_id,
                    name: this.form.purpose,
                    description: this.form.description,
                    organization_name: this.form.organizationSelected,
                    organization_unit: this.form.organizationUnit,
                    access_control: {
                        write: {
                            groups: ["TEST", "TEST2"],
                            users: ["user1"]
                        }
                    },
                    state: RessourceStateEnum.Draft
                }).then(() => {
                    this.$root.$emit('bv::hide::modal', 'purposeManageModal', '#btnRegister')
                })
            }
        },
        onPublished(event: Event) {
            event.preventDefault()
            if(this.validation() === true) {
                this.updateBusinessPurpose({
                    purpose_id: this.getBusinessPurpose.purpose_id,
                    name: this.form.purpose,
                    description: this.form.description,
                    organization_name: this.form.organizationSelected,
                    organization_unit: this.form.organizationUnit,
                    access_control: {
                        write: {
                            groups: ["TEST", "TEST2"],
                            users: ["user1"]
                        }
                    },
                    state: RessourceStateEnum.Published
                }).then(() => {
                    this.$root.$emit('bv::hide::modal', 'purposeManageModal', '#btnPublish')
                })
            }
        },
        deletePurpose(event: Event) {
            event.preventDefault();
            this.deleteBusinessPurpose(this.getBusinessPurpose.purpose_id)
                .then(() => {
                    this.setTableRow({...this.getTableRow, purpose: this.getBusinessPurposes.length})
                    this.$root.$emit('bv::hide::modal', 'purposeManageModal', '#btnDelete')
                })
        },
        editable(value: boolean): boolean{
            this.disabled = !value;
            return !value
        },
        hideModal(){
            this.validate.purpose = ''
            Utils.clearForm(this.form)
        }
    }
})
