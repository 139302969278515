
















import Vue from 'vue'
import FormSelect from '@/components/form-element/form-select/form-select.vue';
import { mapActions, mapGetters } from 'vuex'
import { ScheduleSpan } from '@/models/schedule/scheduleModel';
import { TimeUnit } from '@/common/api.enums';
import { Duration } from '@/models/schedule/duration';

export default Vue.extend({
    name: 'AppointementTimeModified',
    components:{
        FormSelect
    },
    data(){
        return{
            appointmentDelay: {length: 10, time_unit: TimeUnit.Minutes},
            relaxDelay: {length: 0, time_unit: TimeUnit.Minutes},
            appointmentDelayOptions: [
                {value:{length: 10, time_unit: TimeUnit.Minutes}, text: '10 min'},
                {value:{length: 15, time_unit: TimeUnit.Minutes}, text: '15 min'},
                {value:{length: 20, time_unit: TimeUnit.Minutes}, text: '20 min'},
                {value:{length: 30, time_unit: TimeUnit.Minutes}, text: '30 min'},
                {value:{length: 45, time_unit: TimeUnit.Minutes}, text: '45 min'},
                {value:{length: 1, time_unit: TimeUnit.Hours}, text: '1 h'}
            ],
            relaxDelayOptions: [
                {value:{length: 0, time_unit: TimeUnit.Minutes}, text: '0 min'},
                {value:{length: 5, time_unit: TimeUnit.Minutes}, text: '5 min'},
                {value:{length: 10, time_unit: TimeUnit.Minutes}, text: '10 min'},
                {value:{length: 15, time_unit: TimeUnit.Minutes}, text: '15 min'},
                {value:{length: 30, time_unit: TimeUnit.Minutes}, text: '30 min'},
                {value:{length: 1, time_unit: TimeUnit.Hours}, text: '1 h'}
            ],
            selectedOptions: [],
            options: [
                { text: 'Exclure les jours Feriés', value: 'free_days' },
            ],
        }
    },
    computed: {
        ...mapGetters("scheduleTemplate",['getScheduleDays', 'getScheduleTemplate', 'getScheduleSpanFirstKey',
                        'getScheduleDuration', 'getScheduleGraceTime']),
        ...mapGetters("admin", ['getScheduleTemplateModification'])
    },
    methods: {
        ...mapActions("scheduleTemplate",['setScheduleDuration', 'setScheduleGraceTime', 'setScheduleDay']),
        setAppointmentDelayDuration(value: Duration) {
            this.appointmentDelay = value
            const Duration: Duration = this.appointmentDelay
            this.setScheduleDuration(Duration)
            
        },

        setAppointmentRelaxDelay(value: Duration) {
            this.relaxDelay = value
            const GraceTime: Duration  = this.relaxDelay
            this.setScheduleGraceTime(GraceTime)
        },

        getKeys(mapValue: Map<string, ScheduleSpan[]>): Array<string> {
            let keys = new Array<string>()
            for(let key of mapValue.keys()) {
                keys.push(key)
            }
            return keys
        },
        
        firstValueOfScheduleTemplate(): string {
            const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
            let day = 'monday'
            days.forEach((day: string) => {
                if(this.getScheduleTemplate.schedule_days.has(day)) {
                    return day
                }
            })

            return day 
        },


        initialization() {
            this.appointmentDelay = this.getScheduleDuration
            this.relaxDelay = this.getScheduleGraceTime
        }
    },
    beforeMount() {
        this.initialization()
    }
})
