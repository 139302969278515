




























import Vue from 'vue';
import FormInput from '@/components/form-element/form-input/form-input.vue';
import FormSelect from '@/components/form-element/form-select/form-select.vue';
import FormTags from "@/components/form-element/form-tags/form-tags.vue";
import { mapActions, mapGetters } from 'vuex'
import { RessourceStateEnum } from '@/common/api.enums';
import { Utils } from '@/common/utils';
export default Vue.extend({
    name: 'CategoryCreate',
    components:{
        FormInput,
        FormSelect,
        FormTags
    },
    props: [
        'data'
    ],
    data() {
        return {
            form: {
                category: '',
                purpose_id: '',
                description: '',
                sub_category: new Array<string>()
            },
            validate: {
                category: '',
                purpose_id: ''
            },
            sub_categories: this.data
        }
    },
    computed: {
        ...mapGetters("businessPurpose", ['getBusinessPurposeSelectBox', 'getBusinessPurposes', 'getBusinessPurpose', 'getBusinessCategoryTable']),
        ...mapGetters("admin", ["getTableRow"]),
    },
    methods: {
        ...mapActions("businessPurpose", ['setBusinessPurposeSelectBox', 'retrieveBusinessPurposes', 'setSelectBox', 'retrieveBusinessPurpose']),
        ...mapActions("businessCategory", ['createBusinessCategory']),
        ...mapActions("admin", ["setTableRow"]),
        onCategoryNameSet(value: string) {
          this.form.category = value
        },
        onCategoryDescriptionSet(value: string) {
            this.form.description = value
        },
        onCategoryPurposeSet(value: string) {
            this.form.purpose_id = value
            this.retrieveBusinessPurpose(value)
        },
        onSubCategorySet(value: Array<string>) {
            this.form.sub_category = value
        },
        validation(): boolean{
            let validationState = true
            if(this.form.category === '') {
                this.validate.category = 'false'
                validationState = false
            }else{
                this.validate.category = ''
            }
            if(this.form.purpose_id === '') {
                this.validate.purpose_id = 'false'
                validationState = false
            }else{
                this.validate.purpose_id = ''
            }
            return validationState
        },
        onRegister(event: Event) {
            event.preventDefault()
            if(this.form.category.trim() !== ''){
                this.createBusinessCategory({
                    name: this.form.category,
                    description: this.form.description,
                    purpose_id: this.form.purpose_id,
                    state: RessourceStateEnum.Draft,
                    sub_categories: this.form.sub_category
                }).then(() => {
                    this.retrieveBusinessPurposes()
                    this.setTableRow({...this.getTableRow, category: this.getBusinessCategoryTable.length})
                    this.$root.$emit('bv::hide::modal', 'categoryCreateModal', '#btnSubmit')
                })  
            }
        },
        onPublished(event: Event) {
        event.preventDefault()
            if(this.validation() === true){
                this.createBusinessCategory({
                    name: this.form.category,
                    description: this.form.description,
                    purpose_id: this.form.purpose_id,
                    state: RessourceStateEnum.Published,
                    sub_categories: this.form.sub_category
                }).then(() => {
                    this.retrieveBusinessPurposes()
                    this.setTableRow({...this.getTableRow, category: this.getBusinessCategoryTable.length})
                    this.$root.$emit('bv::hide::modal', 'categoryCreateModal', '#btnSubmit')
                }) 
            }
        },
        hideModal(){
            this.validate.category = ''
            Utils.clearForm(this.form)
        }
    }
})
