import Vue from 'vue'
import Vuex, {StoreOptions} from 'vuex'
import { RootState } from './types'
import { auth } from './modules/auth';
import { admin } from './modules/admin/index';
import { layout } from './modules/layout/index';
import { businessPurpose } from './modules/businessPurpose/index'
import { businessCategory } from './modules/businessCategory/index'
import { appointmentLocation } from './modules/appointmentLocation/index'
import { scheduleTemplate } from './modules/scheduleTemplate/index'
import { organization } from './modules/organization/index'

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
    state: {
        version: '1.0.0'
    },
    modules: {
        admin: admin,
        auth: auth,
        layout: layout,
        businessPurpose: businessPurpose,
        businessCategory: businessCategory,
        appointmentLocation: appointmentLocation,
        organization: organization,
        scheduleTemplate: scheduleTemplate
    },
    devtools: false
}

export default new Vuex.Store<RootState>(store)