








import Vue from 'vue'

export default Vue.extend({
    name: 'ScheduleTableHeader',
    props: [
        'data',
    ],
    methods: {
        onChange(){
            this.$emit('daySelectedOrNot', this.data.value, this.data.status)
        }
    },
})
