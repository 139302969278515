import { MutationTree } from 'vuex';
import { User } from "@/plugins/shield/models/user";
import { AuthState } from '@/store/modules/auth/types';


export const mutations: MutationTree<AuthState> = {
  setUser(state, payload: User) {
    state.user = payload;
  },

};
