
















































import Vue from 'vue'
import FormInput from "@/components/form-element/form-input/form-input.vue";
import FormSelect from "@/components/form-element/form-select/form-select.vue";
import FormTags from "@/components/form-element/form-tags/form-tags.vue";
import { mapGetters, mapActions } from "vuex";
import { BusinessPurpose } from '@/models/metadata/businessMetadata';
import { RessourceStateEnum } from '@/common/api.enums';
import { BusinessCategoryModel } from '@/models/metadata/businessMetadata';
import { Utils } from '@/common/utils';
export default Vue.extend({
    name: 'CategoryManage',
    components: {
       FormInput,
       FormSelect,
       FormTags
    },
    data(){
        return {
            form: {
                category: '',
                purpose_id: '',
                description: '',
                sub_category: new Array<string>()
            },
            validate: {
                category: '',
                purpose_id: ''
            },
            disabled: true,
            deleteBtn: true
        }
    },
    computed: {
        ...mapGetters('businessCategory', ['getBusinessCategory']),
        ...mapGetters("businessPurpose", ['getBusinessPurposeSelectBox', 'getBusinessPurposes', 'getBusinessPurpose', 'getBusinessCategoryTable']),
        ...mapGetters("admin", ["getTableRow"]),
    },
    methods: {
        ...mapActions("businessCategory", ['updateBusinessCategory', 'deleteBusinessCategory']),
        ...mapActions("businessPurpose", ['setBusinessPurposeSelectBox', 'retrieveBusinessPurposes', 'setSelectBox', 'retriveBusinessPurposeForCategory']),
        ...mapActions("admin", ["setTableRow"]),
        modalInitialize(){
            this.getPurposeOfTheCategory();
            this.onCategoryNameSet(this.getBusinessCategory.name);
            this.onCategoryDescriptionSet(this.getBusinessCategory.description)
            this.onSubCategorySet(this.getBusinessCategory.sub_categories)
        },
        getPurposeOfTheCategory() {
            this.getBusinessPurposes.forEach((purpose: BusinessPurpose) => {
                purpose.categories.find((category: BusinessCategoryModel) => {
                    if(category.category_id === this.getBusinessCategory.category_id){
                        this.form.purpose_id = purpose.purpose_id
                    }
                })
            });
        },
        onCategoryNameSet(value: string) {
          this.form.category = value
        },
        onCategoryDescriptionSet(value: string) {
            this.form.description = value
        },
        onCategoryPurposeSet(value: string) {
            this.form.purpose_id = value
        },
        onSubCategorySet(value: Array<string>) {
            this.form.sub_category = value
        },
        validation(): boolean{
            let validationState = true
            if(this.form.category === '') {
                this.validate.category = 'false'
                validationState = false
            }else{
                this.validate.category = ''
            }
            if(this.form.purpose_id === '') {
                this.validate.purpose_id = 'false'
                validationState = false
            }else{
                this.validate.purpose_id = ''
            }
            return validationState
        },
        onRegister(event: Event) {
            event.preventDefault()
            if(this.form.category.trim() !== '') {
                this.updateBusinessCategory({
                category_id: this.getBusinessCategory.category_id,
                name: this.form.category,
                description: this.form.description,
                state: RessourceStateEnum.Draft,
                sub_categories: this.form.sub_category
                }).then(() => {
                    this.retrieveBusinessPurposes()
                        .then(() => this.retrieveBusinessPurposes())
                        this.$root.$emit('bv::hide::modal', 'categoryManageModal', '#btnSubmit')
                })
            }
        },
        onPublished(event: Event) {
            event.preventDefault()
            if(this.validation() === true){
                this.updateBusinessCategory({
                    category_id: this.getBusinessCategory.category_id,
                    name: this.form.category,
                    description: this.form.description,
                    state: RessourceStateEnum.Published,
                    sub_categories: this.form.sub_category
                }).then(() => {
                    this.retrieveBusinessPurposes()
                        .then(() => this.setBusinessPurposeSelectBox(this.getBusinessPurposes))
                        this.$root.$emit('bv::hide::modal', 'categoryManageModal', '#btnSubmit')
                })
            }
        },
        deleteCategory(event: Event) {
            event.preventDefault();
            this.deleteBusinessCategory(this.getBusinessCategory.category_id).then(() => {
                this.retrieveBusinessPurposes()
                    .then(() => this.setBusinessPurposeSelectBox(this.getBusinessPurposes))
                this.setTableRow({...this.getTableRow, category: this.getBusinessCategoryTable.length})
                this.$root.$emit('bv::hide::modal', 'categoryManageModal', '#btnDelete')
            })
        },
        showModal() {
            this.modalInitialize()
            if(this.getBusinessCategory.state === RessourceStateEnum.Draft){
                this.disabled = false
                this.deleteBtn = false
            }else{
                this.disabled = true
                this.deleteBtn = true
            }
        },
        editable(value: boolean): boolean{
            this.disabled = !value;
            return !value
        },
        hideModal(){
            this.validate.category = ''
            Utils.clearForm(this.form)
        }
    },
    beforeMount() {
        this.retrieveBusinessPurposes().then(() => {
            this.setBusinessPurposeSelectBox(this.getBusinessPurposes)
        })
    }
})
