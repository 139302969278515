












































import { Vue } from "vue-property-decorator";
import FilterPrime from "@/components/table-filter/filter.vue";
import TableView from "@/components/table-view/table-view.vue";
import { mapGetters, mapActions } from "vuex";
export default Vue.extend({
  name: "CategoriesTab",
  components: {
    TableView,
    FilterPrime
  },
  data() {
    return {
      selected: '',
      fields: [
        {
          key: "name",
          label: "Catégorie",
          sortable: true,
        },
        {
          key: "purpose",
          label: "Thème",
          sortable: true,
        },
        {
          key: "description",
          label: "Description",
          sortable: false,
        },
        {
          key: "sub_categories",
          label: "Sous-categories",
          sortable: false,
          formatter: (value: []) => {
              return value.toString()
          }
        },
        { key: "state", label: "Statut", sortable: true},
      ],
      currentPage: 1,
      perPage: 10,
      fixed: false,
      theAnotherPage: "/creation-rendez-vous/gestion-theme",
    };
  },
  computed: {
    ...mapGetters("admin", ["getPerPage", "getSearchCategoryText", 
                  "getTableRow", "getTableFilterOn"]),
    ...mapGetters("businessCategory", ["getBusinessCategories", "getFilterPurposeSearch",
                   "getFilterStatut", "getFilterSearchText"]),
    ...mapGetters("businessPurpose", [ "getBusinessPurposes",
        "getBusinessPurposeSelectBox", "getBusinessCategoryTable"
      ]),
    filteredItems() {
      const filtered = this.getBusinessCategoryTable.filter((item) => {
          return (
              (!this.getFilterStatut || item.state === this.getFilterStatut) &&
              (
                  !this.getFilterSearchText || this.getFilterSearchText.trim() === '' ||
                  item.name.toLowerCase().includes(this.getFilterSearchText) ||
                  item.description.toLowerCase().includes(this.getFilterSearchText)
              ) &&
              (!this.getFilterPurposeSearch || item.purpose_id === this.getFilterPurposeSearch)
          );
      });
      return filtered;
    },
    sortOptions(): any {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    rows(): number {
      return this.getTableRow.category
    },
  },
  methods: {
    ...mapActions("businessPurpose", ["retrieveBusinessPurposes"]),
    ...mapActions("businessCategory", ["setFilterPurposeSearch", "setFilterStatut", "setFilterSearchText"]),

    ...mapActions("admin", ["setTableRow",]),
    filterStateSet(value: string){
      this.setFilterStatut(value)
    },
    filterSearchText(value: string){
      this.setFilterSearchText(value)
    },
    filterSelectBox(value: string){
      this.setFilterPurposeSearch(value)
    }
  },
  mounted() {
      // Set the initial number of items
      this.retrieveBusinessPurposes().then(() => this.setTableRow({...this.getTableRow, category: this.getBusinessCategoryTable.length}))
  },
});
