











import Vue from 'vue';
import AppointementTimeSetter from './appointment-time-setter/appointment-time-setter.vue';
export default Vue.extend({
    name: 'ScheduleTemplateHeader',
    components: {
        AppointementTimeSetter,
    },
})
