import { BusinessCategoryCreate, BusinessCategoryUpdate } from "@/dto/metadata/metadataDto"
import { ApiResponse } from "@/models/http/ApiResponse"
import { BusinessCategoryModel } from "@/models/metadata/businessMetadata"
import { HttpBaseService } from "./httpBase.service"
import { scheduleManagerApiUrl } from "@/app.config";


export class BusinessCategoryService extends HttpBaseService{

    private static classInstance?: BusinessCategoryService
    static apiBaseUrl = scheduleManagerApiUrl

    constructor(token:string) {
        super(token, BusinessCategoryService.apiBaseUrl)
    }


    public static getInstance(token:string): BusinessCategoryService {

        if(!this.classInstance) {
            this.classInstance = new BusinessCategoryService(token)
        }

        return this.classInstance
    }


    public createBusinessCategory(businessCategory: BusinessCategoryCreate) : Promise<ApiResponse<BusinessCategoryModel>> {  
        const apiResponse = new ApiResponse<BusinessCategoryModel>()
        const businessCategoryCreate = {
            resource_type: 'business_category',
            data: businessCategory
        } 
        return this.instance.post('/admin/metadata/create', businessCategoryCreate)
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            }) 
            .catch(error => {
                apiResponse.apiError = error.response.data;
                return apiResponse;
            })
    }

    public updateBusinessCategory(businessCategory: BusinessCategoryUpdate) : Promise<ApiResponse<BusinessCategoryModel>> {
        const apiResponse = new ApiResponse<BusinessCategoryModel>()
        const businessCategoryUpdate = {
            resource_type: 'business_category',
            data: businessCategory
        }
        return this.instance.put('/admin/metadata/update', businessCategoryUpdate)
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            }) 
            .catch(error => {
                apiResponse.apiError = error.response.data;
                return apiResponse;
            })
    }

    public loadBusinessCategory(resourceId: string) : Promise<ApiResponse<BusinessCategoryModel>>{
        const apiResponse = new ApiResponse<BusinessCategoryModel>()
        return this.instance.get(`/admin/metadata/business_category/${resourceId}`)
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            })
            .catch(error => {
                apiResponse.apiError = error.response.data;
                return apiResponse;
            })
    }

    public deleteBusinessCategory(resourceId: string) : Promise<ApiResponse<BusinessCategoryModel>> {
        const apiResponse = new ApiResponse<BusinessCategoryModel>()
        return this.instance.delete(`/admin/metadata/business_category/${resourceId}`)
            .then(response => {
                apiResponse.data = response.data;
                return apiResponse;
            }) 
            .catch(error => {
                apiResponse.apiError = error.response.data;
                return apiResponse;
        })
    }

}